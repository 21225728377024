/* .chat_message .row {
  justify-content: space-around;
} */

.chat_img{
  height: 40px;
  width: 40px;
}
.chat_img img{
  height: 100%;
  width: 100% !important;
  object-fit: cover;
  border-radius: 50%;
}

.darkbody .chat_message {
  height: 87.2vh;
  overflow: hidden;
}
.left_sidemenu {
  background: #f5f5f5;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  padding-top: 20px;
  height: 100%;
}

.darkbody .left_sidemenu {
  background: #262626;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  padding-top: 20px;
  height: 100%;
  color: #fff;
}

.center_message span:nth-child(1) {
  display: flex;
  align-items: center;
  gap: 10px;
}

.darkbody .search_chat .form-control {
  border: #a6a6a6 solid 1px !important;
  color: #fff !important;
}

.darkbody .search_chat .form-control::placeholder {
  color: #a6a6a6 !important;
}

.darkbody .chat_send img {
  border: #a6a6a6 solid 1px !important;
}

.center_message .chat_prfoilecenter h2 {
  font-weight: 500;
  font-size: 14px;
  color: #0d0d0d;
}

.center_message .chat_prfoilecenter h5 {
  font-size: 12px;
  color: #0d0d0d;
  opacity: 0.5;
  padding-top: 6px;
}

.darkbody .center_message .chat_prfoilecenter h5 {
  font-size: 12px;
  color: #fff;
  opacity: 0.5;
  padding-top: 6px;
}

.center_message span:nth-child(1) img {
  width: 35px;
}

.center_message span:nth-child(2) img {
  padding-left: 15px;
}

.center_message {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 0.5px solid #f5f5f5;
  border-bottom: 0.5px solid #bababa;
  padding: 7.7px;
}

.darkbody .center_message {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 0.5px solid #a6a6a6;
  border-bottom: 0.5px solid #a6a6a6;
  padding: 7.7px;
}

.center_message .chat_prfoilecenter h2 {
  font-weight: 500;
  font-size: 14px;
  color: #0d0d0d;
}
.darkbody .center_message .chat_prfoilecenter h2 {
  font-weight: 500;
  font-size: 14px;
  color: #fff;
}

.darkbody .dark-chat {
  background-color: #262626;
}
.dark-chat.mob-chat{
  border: 1px solid rgba(0, 0, 0, .1);
}
.message_enter {
  /* margin: 0 20px; */
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
  height: calc(600px - 76px);
  padding: 12px 15px 0px 15px;
  overflow-y: auto;
}
.messagebottom_center {
  display: flex;
  gap: 10px;
  align-items: flex-end;
  max-width: 70%;
  width: auto;
}

.messagebottom_center img {
  width: 34px !important;
  height: 34px;
  border-radius: 50%;
  object-fit: cover;
}
.messagebottom_center span{
  width: calc(100% - 35px);

} 
.messagebottom_center p {
  background: #f0f0f0;
  border-radius: 8px 8px 8px 0px;
  padding: 10px;
  width: auto;
  line-height: 1.4;
  color: #818181;
  max-width: 100%;
  font-size: 12px;
  display: table;
}
.right_chat{
  margin-left: auto;
  /* max-width: 70%;
  width: 100%; */
}
.darkbody .messagebottom_center p {
  background: #353434;
  border-radius: 8px 8px 8px 0px;
  padding: 10px;
  width: auto;
  line-height: 1.4;
  max-width: 70%;
  font-size: 12px;
  display: table;
  color: #fff;
}

.messagebottom_center h6 {
  padding-top: 7px;
  font-weight: 400;
  font-size: 10px;
  color: #c3c3c3;
}
.chat_send {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 20px;
  padding: 0px 10px;
}

.chat_send .search-bottom {
  background: transparent;
  display: flex;
}

.chat_send img {
  height: 24px;
}

/* .chat_send img:nth-child(2) {
    border: 1px solid rgba(13, 13, 13, 0.1);
    border-radius: 3px;
    padding: 3px;
  }
   */
.chat_send .search_chat input {
  background: transparent;
  border: 1px solid rgba(13, 13, 13, 0.1);
  border-radius: 3px;
  height: unset !important;
  line-height: unset;
  padding: 5px 50px 5px 10px !important;
}

.ic-send {
  border-left: 1px solid #ccc;
  padding-left: 12px;
}
.chat_send .search_chat {
  position: relative;
}
.right_sidebarchat .top_innerimg {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 0 10px;
  margin-bottom: 24px;
}

.right_sidebarchat .top_innerimg img:nth-child(1) {
  height: 100px;
}

.right_sidebarchat .top_innerimg span {
  text-align: center;
  background: #ffffff;
  border: 1px solid rgba(13, 13, 13, 0.1);
  border-radius: 8px;
  padding: 26px 7px;
}

.right_sidebarchat .top_innerimg span img {
  padding: 6px 0;
}

.right_sidebarchat .top_innerimg span h6 {
  font-weight: 400;
  font-size: 12px;
  line-height: 10px;
  color: #cc1747;
}

.right_sidebarchat {
  background: #f5f5f5;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 15px;
  height: 100%;
}
.chat-modal .right_sidebarchat {
  background: #fff;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  padding-top: 20px;
  height: 645px;
  overflow: auto;
}

/* .right-side-size{
  width: 50%;
  height: 100px;
  border-radius: 5px;
  margin-bottom: 10px;
} */
.flex-chat-ic-2 .right-side-size img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.flex-chat-ic-2 .right-side-size {
  width: 50%;
  height: 100px;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 0px 5px;
}
.upload_profile .right-side-size {
  width: 25%;
}
.upload_profile .flex-wrap {
  gap: 15px;
}

.upload_profile .right-side-size {
  width: calc(25% - 14px);
}
.darkbody .right_sidebarchat {
  background: #1b1b1b;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  padding-top: 20px;
  height: 100%;
  color: #fff;
}

.info_side {
  display: flex;
  justify-content: space-between;
  padding: 15px 10px;
  border-top: 0.5px solid #bababa;
  border-bottom: 0.5px solid #bababa;
}

.info_side .pull-left,
.Verifi_cations .pull-left {
  font-weight: 500;
  font-size: 14px;
  color: #000;
}
.userInfoIcon {
  cursor: pointer;
}
.darkbody .userInfoIcon {
  cursor: pointer;
  color: #fff;
}

.info_side .pull-right {
  font-weight: 400;
  font-size: 14px;
  color: #cc1747;
}

.darkbody .info_side .pull-right {
  font-weight: 400;
  font-size: 14px;
  color: #a6a6a6;
}
/* new css chat */
/* Message-page Start */

.message_tabs .inner_content {
  align-items: center;
}

.message_tabs .search-bottom input {
  background: #ffffff;
  border: 1px solid rgba(13, 13, 13, 0.1);
  border-radius: 8px;
  height: 48px !important;
}

.message_tabs .search-bottom img {
  top: 16px;
}

.message_tabs .interesttabs .search-bottom {
  top: -5px;
}

.checkboxshow .show_unread {
  cursor: pointer;
  display: block;
  position: relative;
  padding-left: 35px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #f11b54;
  margin-bottom: 0;
}

.show_unread input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkboxshow .show_unread .checkmark {
  position: absolute;
  top: -1px;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #f11b54;
  border-radius: 4px;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.show_unread input:checked ~ .checkmark:after {
  display: block;
}

.show_unread .checkmark:after {
  left: 7px;
  top: 3px;
  width: 6px;
  height: 10px;
  border: solid #e50e47;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkboxshow .show_unread input:checked ~ .checkmark {
  border: 1px solid #f11b54;
  border-radius: 4px;
}

/* Message-page End */

/* chatmessage-page Start */

.left_sidemenu {
  background: #f5f5f5;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  padding-top: 12px;
  height: 645px;
  overflow: auto;
}

.left_sidemenu ul {
  padding-left: 0;
  list-style: none;
}

.left_sidemenu ul li.active {
  color: #e50e47;
}

.left_sidemenu ul li {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #c3c3c3;
  padding: 11px 26px;
  border-bottom: 0.5px solid #bababa;
  list-style: none;
}

.left_sidemenu ul li:last-child {
  border-bottom: unset;
}

.center_message {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.center_message span:nth-child(1) {
  display: flex;
  align-items: center;
  gap: 10px;
}

.center_message .chat_prfoilecenter h2 {
  font-weight: 500;
  font-size: 14px;
  color: #0d0d0d;
}

.center_message .chat_prfoilecenter h5 {
  font-size: 12px;
  color: #0d0d0d;
  opacity: 0.5;
  padding-top: 6px;
}

.center_message span:nth-child(1) img {
  width: 35px;
}

.center_message span:nth-child(2) img {
  padding-left: 15px;
}

.center_message {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 0.5px solid #f5f5f5;
  border-bottom: 0.5px solid #bababa;
  padding: 7.7px 15px;
}

.messagebottom_center {
  display: flex;
  gap: 10px;
  align-items: flex-end;
  margin-bottom: 20px;
}

.messagebottom_center img {
  width: 34px !important;
  height: 34px;
  border-radius: 50%;
}

.messagebottom_center h6 {
  padding-top: 7px;
  font-weight: 400;
  font-size: 10px;
  color: #c3c3c3;
}

.chat_send {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 20px;
}

.chat_send .search-bottom {
  background: transparent;
  display: flex;
}

.chat_send img {
  height: 24px;
}

.chat_send img:nth-child(2) {
  border: 1px solid rgba(13, 13, 13, 0.1);
  border-radius: 3px;
  padding: 5px;
}
.search_chat input {
  padding-right: 100px !important;
}

.chat_send .search_chat .ic-send {
  right: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.chat_send .search_chat {
  position: relative;
}

/* .message_enter {
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 95.4%;
} */

.right_sidebarchat .top_innerimg {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 0 10px;
  margin-bottom: 24px;
}

.right_sidebarchat .top_innerimg img:nth-child(1) {
  height: 100px;
}

.right_sidebarchat .top_innerimg span {
  text-align: center;
  background: #ffffff;
  border: 1px solid rgba(13, 13, 13, 0.1);
  border-radius: 8px;
  padding: 26px 7px;
}

.right_sidebarchat .top_innerimg span img {
  padding: 6px 0;
}

.right_sidebarchat {
  background: #f5f5f5;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  padding-top: 20px;
  height: 645px;
  overflow: auto;
}
.modal-content.Edit_your_info.chat-modal {
  padding: 0px;
  padding-top: 15px;
}

.info_side {
  display: flex;
  justify-content: space-between;
  padding: 15px 10px;
  border-top: 0.5px solid #bababa;
  border-bottom: 0.5px solid #bababa;
}

.info_side .pull-left,
.Verifi_cations .pull-left {
  font-weight: 500;
  font-size: 14px;
  color: #c3c3c3;
}

.info_side .pull-right {
  font-weight: 400;
  font-size: 14px;
  color: #cc1747;
}

.Verifi_cations {
  display: flex;
  justify-content: space-between;
  padding: 15px 10px;
}

.right_sidebarchat h2 {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  background: #d9d9d9;
  padding: 15px 10px;
  color: #000;
}

.right_sidebarchat .info_details .pull-right {
  color: #000;
}

.right_sidebarchat .info_details h5 {
  font-weight: 400;
  font-size: 14px;
  color: #8d8d8d;
  padding: 15px 10px;
}

.info_side.last_side {
  border-bottom: unset;
  margin-bottom: 15px;
}

.right_sidebarchat .info_details ul {
  padding: 20px 5px;
  margin-bottom: 0;
  list-style: none;
  display: inline-block;
  width: 100%;
}

.right_sidebarchat .info_details ul a {
  background: #ffffff;
  border: 0.5px solid rgba(13, 13, 13, 0.2);
  border-radius: 40px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #0d0d0d;
  padding: 10px 16px;
  float: left;
  margin: 5px;
}
/* 
.chat_message .row {
  justify-content: space-around;
} */
.inbox-sidebar {
  display: none;
}
/* chatmessage-page End */
.cross-sidebar {
  display: none;
}
.mob-closs i {
  display: none;
}
.mob-closs img {
  display: block;
}
.p_edit {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.p_edit span {
  font-size: 14px;
  color: #e50e47;
  font-weight: 500;
  text-decoration: underline;
}

.filter-msg {
  filter: blur(4px) !important;
}
@media (max-width: 767px) {
  .messagebottom_center p {
    width: 100%;
  }
  .right_sidebarchat {
    margin-top: 15px;
  }

  .left_sidemenu.inbox-slide {
    left: 0;
  }

  .cross-sidebar {
    display: block;
    color: #000;
    position: absolute;
    right: 14px;
    top: 24px;
    font-size: 24px;
  }

  .inbox-sidebar {
    display: block;
    padding: 5px 15px;
    margin-bottom: 10px;
    border: 0px;
    font-size: 16px;
    color: #fff;
    left: -150%;
    background-color: #e50e47;
    border-radius: 2px;
  }

  .left_sidemenu {
    border-radius: 0px;
    padding-top: 21px;
    height: 100vh;
    position: fixed;
    width: 100%;
    left: -150%;
    top: 0;
    z-index: 99;
    transition: all 0.5s;
  }
  .mob-closs i {
    display: block;
  }
  .member-loading {
    font-size: 15px;
  }
  .mob-closs {
    height: 36px;
    background-color: #e50e47;
    border-radius: 2px;
    color: #fff !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px !important;
    width: auto;
    padding: 5px 10px;
  }
  .mob-closs i {
    font-size: 20px;
  }
  .mob-closs img {
    display: none;
  }
}
