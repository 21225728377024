.search-bottom {
  position: relative;
}

.search-bottom img {
  position: absolute;
  left: 18px;
  top: 18px;
}

.search-bottom .form-control:focus {
  color: #000;
  background-color: transparent;
}

.search-bottom input::placeholder {
  color: #f11b54;
}

.search-bottom input::-ms-value {
  color: #f11b54;
}
.last_seen {
  font-size: 10px;
  text-align: right;
  font-weight: 400;
}
.msg_unread {
  height: 26px;
  width: 26px;
  background-color: #fec6c6;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: auto;
  margin-top: 7px;
}
.msg_unread p {
  font-size: 12px;
  font-weight: bold;
  color: #000;
}
.inner_content {
  border-bottom: 1px solid #ccc;
  padding: 13px 0px;
  margin: 5px 0px;
}
.inner_content.active {
  background-color: #dfdfdf;
}
.filter_side .accordion-item {
  background-color: transparent !important;
}
.filter_side .accordion-button {
  background-color: transparent !important;
}
.border-ll {
  border-left: 1px solid #ccc;
}
.online_dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  bottom: 0px;
  background-color: darkgreen;
  border: 2px solid #fff;
}

.message_tabs .search-bottom input {
  background: #ffffff;
  border: 1px solid rgba(13, 13, 13, 0.1);
  border-radius: 8px;
  height: 48px !important;
}

.message_tabs .search-bottom img {
  top: 16px;
}

.message_tabs .interesttabs .search-bottom {
  top: 0px;
}

.chat_send .search-bottom {
  background: transparent;
  display: flex;
}

.checkboxshow .show_unread {
  cursor: pointer;
  display: block;
  position: relative;
  padding-left: 35px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #f11b54;
  margin-bottom: 0;
}

.checkboxshow .show_unread .checkmark {
  position: absolute;
  top: -1px;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #f11b54;
  border-radius: 4px;
}

.checkboxshow .show_unread input:checked ~ .checkmark {
  border: 1px solid #f11b54;
  border-radius: 4px;
}

.show_unread input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.show_unread input:checked ~ .checkmark:after {
  display: block;
}

.px-50 {
  padding: 0px 50px;
}
.btn-dot {
  border: none;
  padding: 0px;
  background-color: transparent;
}
.flex-drop .dropdown-toggle::after {
  border: none !important;
}
.flex-drop {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: end;
}
ul.dropdown-menu {
  border: none;
  padding: 10px 0px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}
ul.dropdown-menu p {
  padding: 4px 17px;
  font-size: 12px;
}

.message_tabs .inner_content {
  align-items: center;
}
@media (max-width: 767px) {
  .tab-mobs ul li label {
    font-size: 16px !important;
  }
  .tab-mobs ul {
    gap: 20px;
  }
  .border-ll {
    border: none;
  }
}
