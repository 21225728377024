.input {
    appearance: none;
    resize: none;
    font-weight: 400;
    font-size: 12px !important;
    background: #F2F2F2;
    border: 1px solid rgba(13, 13, 13, 0.1);
    border-radius: 12px;
    padding: 14px 10px;
}

.error {
    color: red;
    font-size: 13px;
    font-weight: 500;
    display: block;
}