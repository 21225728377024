@import "@fortawesome/fontawesome-free/css/all.min.css";

.home_page .listing-sec {
  padding: 40px 0;
}

.add-listing .nav-listings li {
  margin: 5px 15px 5px 0px;
}

.add-listing .nav-listings li a {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: rgba(13, 13, 13, 0.7);
  text-align: center;
}

.add-listing .nav-listings li a p {
  margin: 10px 0px;
}

.add-listing .nav-listings li {
  margin: 5px 15px 5px 0px;
}

.add-listing .nav-listings li a {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: rgba(13, 13, 13, 0.7);
  text-align: center;
}

.add-listing .nav-listings li a p {
  margin: 10px 0px;
}

.nav-listings {
  margin: 20px 0px;
}

.list-resend .warning {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  color: #0d0d0d;
}

.verify-sec .warning .fa {
  color: #f11b54;
}

.listing-sec {
  padding: 70px 0px;
}

.home_page .listing-sec {
  padding: 40px 0;
}

.listing-5 li {
  width: 20%;
  padding: 0px 10px;
  overflow: hidden;
}
.tabs_member {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow: hidden;
  overflow-x: auto;
}
.tabs_member li button {
  color: #949494;
}
.tabs_member li button:hover {
  color: #949494;
}

.tabs_member li button.active {
  color: #fff !important;
  background-color: #e50e47 !important;
}

/* .list-img-size {
    height: 227px;
    border-radius: 12px;
    margin-bottom: 16px;
    width: 100%;
    overflow: hidden;
    position: relative;
    transition: transform .2s;
}

.list-img-size:hover {
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    cursor: pointer;
}

.list-img-size img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 12px;
}


.list-img-size:hover {
    box-shadow: 0px 5px 8px rgba(0, 0, 0, .2);
} */

.search_page .list-img-size {
  height: unset;
}

.diamond-b {
  background: #f11b54;
  border-radius: 4px;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  color: #ffffff;
  padding: 4px 6px;
  position: absolute;
  top: 8px;
  left: 8px;
  text-decoration: none;
}

.diamond-b:hover {
  color: #fff;
}

.n-adress {
  position: absolute;
  bottom: 8px;
  left: 8px;
}

.n-adress h4 {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  padding: 3px 0px;
  color: #ffffff;
}

.n-adress p {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
}
.n-adress h6 {
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  margin-top: 2px;
}
.search_page .n-adress {
  position: relative;
  background: #f0f0f0;
  box-shadow: 0px 2px 16px -3px rgba(0, 0, 0, 0.2);
  border-bottom-right-radius: 8px;
  padding: 10px;
  border-bottom-left-radius: 8px;
  bottom: 0;
  left: 0;
  z-index: 9;
}

.search_page .n-adress img {
  position: absolute;
  top: -20px;
  right: 8px;
  width: 50px;
  height: 50px;
}

.search_page .n-adress h4 {
  color: #0d0d0d;
}

.search_page .n-adress p {
  color: #0d0d0d7d;
  padding: 4px 0;
}

.search_page .n-adress h6 {
  font-size: 10px;
  color: #0d0d0d;
  font-weight: 400;
}

.search-bottom {
  position: relative;
}

.search-bottom img {
  position: absolute;
  left: 18px;
  top: 18px;
}

.search-bottom .form-control:focus {
  color: #000;
  background-color: transparent;
}

.search-bottom input::placeholder {
  color: #f11b54;
}

.search-bottom input::-ms-value {
  color: #f11b54;
}

.message_tabs .search-bottom input {
  background: #ffffff;
  border: 1px solid rgba(13, 13, 13, 0.1);
  border-radius: 8px;
  height: 48px !important;
}

.message_tabs .search-bottom img {
  top: 16px;
}

.message_tabs .interesttabs .search-bottom {
  top: -5px;
}

.chat_send .search-bottom {
  background: transparent;
  display: flex;
}

.f-member {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0d0d0d;
  margin: 24px 0px;
}

.form-control {
  height: 52.33px !important;
  font-weight: 500;
  font-size: 15.2643px;
  line-height: 35px;
  color: #000;
  padding: 10px 15px;
  background: #f2f2f2;
  border: 1px solid rgba(13, 13, 13, 0.1);
  border-radius: 12px;
}

.darkbody .form-control {
  height: 52.33px !important;
  font-weight: 500;
  font-size: 15.2643px;
  line-height: 35px;
  color: #fff;
  padding: 10px 15px;
  background: #1b1b1b;
  border: 1px solid #a6a6a6;
  border-radius: 12px;
}

.form-control::placeholder {
  color: rgba(13, 13, 13, 0.4);
}

.form-control:focus {
  color: #000;
  background: #f2f2f2;
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}

.listing-sec {
  padding: 70px 0px;
}
.home_page .listing-sec {
  padding-bottom: 20px;
  padding-top: 0px;
}
.Sort_Recently {
  padding: 0 0 70px;
}
.perpage h5 {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #f11b54;
}

.perpage h6 {
  font-weight: 400;
  font-size: 18px;
  color: #0d0d0d;
  opacity: 0.6;
}
.results_fount {
  background: #f2f2f2;
  border-radius: 12px;
  padding: 12px 24px;
  flex-direction: column;
  align-items: flex-end;
}
.darkbody .results_fount {
  background: #1b1b1b;
  border-radius: 12px;
  padding: 12px 24px;
  flex-direction: column;
  align-items: flex-end;
}

.results_fount select {
  color: #f11b54;
}
.results_fount select option {
  color: #000;
}

.list-img-size {
  height: 260px;
  border-radius: 12px;
  margin-bottom: 16px;
  width: 100%;
  overflow: hidden;
  position: relative;
  transition: transform 0.2s;
  position: relative;
}
.list-img-size::after {
  position: absolute;
  content: "";
  /* background-image: linear-gradient(to bottom, rgba(0,0,0,.2) 20%, rgba(0,0,0,.7)60%); */
  background: linear-gradient(
      to bottom,
      rgb(0 0 0 / 0%) 5%,
      rgb(0 0 0 / 58%) 70%
    )
    rgb(21 21 21 / 1%) 12%;
  width: 100%;
  height: 60px;
  left: 0;
  bottom: 0;
}

.list-img-size img:hover {
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.list-img-size img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 12px;
  transform: scale(1);
  transition: all 0.5s;
}
.list-img-size:hover {
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.2);
}
.search_page .list-img-size {
  height: unset;
}

.n-adress {
  position: absolute;
  bottom: 8px;
  left: 8px;
  z-index: 9;
}

.n-adress h4 {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  padding: 3px 0px;
  color: #ffffff;
}

.n-adress p {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
}
.search_page .n-adress {
  position: relative;
  background: #f0f0f0;
  box-shadow: 0px 2px 16px -3px rgba(0, 0, 0, 0.2);
  border-bottom-right-radius: 8px;
  padding: 10px;
  border-bottom-left-radius: 8px;
  bottom: 0;
  left: 0;
  z-index: 9;
}

.search_page .n-adress img {
  position: absolute;
  top: -20px;
  right: 8px;
  width: 50px;
  height: 50px;
}

.search_page .n-adress h4 {
  color: #0d0d0d;
}

.search_page .n-adress p {
  color: #0d0d0d7d;
  padding: 4px 0;
}
.cursor-none {
  cursor: default;
}

.search_page .n-adress h6 {
  font-size: 10px;
  color: #0d0d0d;
  font-weight: 400;
}
.pagination_search {
  background: #f2f2f2;
  border-radius: 12px;
  padding: 15px;
}

.pagination_search span:nth-child(2) ul {
  padding-left: 0;
  list-style: none;
  display: inline-flex;
  gap: 30px;
}

.pagination_search span {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #0d0d0d;
  opacity: 0.5;
}

.pagination_search span i {
  padding: 0 10px;
}

.btn-punlic {
  border: 1px solid #e50e47;
  background: transparent;
  color: #e50e47;
  border-radius: 10px;
  text-decoration: none;
  font-size: 16px;
  padding: 10px 41px;
  float: left;
  margin-top: 13px;
}
.btn-punlic:hover {
  color: #fff;
  background-color: #e50e47;
}

.btn-heart.user-list {
  height: 24px;
  width: 24px;
}

@media (max-width: 1199px) {
  .listing-5 li {
    width: 25%;
  }
}
@media (max-width: 992px) {
  .chat-btn-filter {
    transform: rotate(90deg);
    position: absolute;
    left: -13px;
    top: 20%;
    padding: 3px 16px !important;
    border-radius: 0px !important;
  }
  .btn-cross i {
    font-size: 25px;
    color: #cf0d40;
  }
  .btn-filter {
    display: block !important;
  }
  .search_top {
    padding: 20px 0px 20px 20px;
    position: fixed;
    z-index: 999;
    background: #fff;
    left: -150%;
    box-shadow: 0px 7px 11px rgba(0, 0, 0, 0.2);
    top: 0;
    width: 100%;
    height: 100vh;
    transition: all 0.5s;
  }

  .search_top.filter-toggle {
    left: 0;
  }

  .overflow_top {
    height: calc(100vh - 105px) !important;
  }
  .reset_search {
    padding: 26px 15px 2px 0px !important;
  }
}

@media (max-width: 767px) {
  .listing-5 li {
    width: 33.33%;
  }
}

@media (max-width: 576px) {
  .listing-5 li {
    width: 50%;
  }
}
