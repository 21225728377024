.search_top h4 {
  font-weight: 500;
  font-size: 22px;
  color: #0d0d0d;
}
.search_top select {
  background-color: #f2f2f2;
  border: 1px solid rgba(13, 13, 13, 0.1);
  border-radius: 12px;
  padding: 10px;
}
.search_top h5 {
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 5px;
}
.search_top select {
  background-color: #f2f2f2;
  border: 1px solid rgba(13, 13, 13, 0.1);
  border-radius: 12px;
  padding: 10px;
  font-weight: 400;
  font-size: 16px;
}
.btn-chat {
  position: absolute;
  right: 10px;
  top: 10px;
  background: rgba(0, 0, 0, 0.3);
  border: none;
  color: rgb(255, 255, 255);
  cursor: pointer;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}
.search_top .reset_search button {
  background: #f11b54;
  border-radius: 6px;
  padding: 3px 16px;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
}

.search_top .reset_search h6 {
  font-weight: 500;
  font-size: 12px;
  color: #b7abab;
  cursor: pointer;
}

.search_top .distance h6 {
  /* background: #F2F2F2;
    border: 1px solid rgba(13, 13, 13, 0.1); */
  border-radius: 3px;
  padding: 3px 3px 3px 3px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.darkbody .distance {
  color: #a6a6a6;
}

.darkbody .search_top .distance h6 {
  background: #1b1b1b;
  border: 1px solid #a6a6a6;
  border-radius: 3px;
  padding: 3px 21px 3px 3px;
  margin-right: 5px;
}

.search_top input[type="range"] {
  /* -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 7px;
    border-radius: 5px;
    background: #0d0d0d40;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s; */
}

.search_location {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 8px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.darkbody .search_location {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 17px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #a6a6a6;
}

.search_location input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.search_location .checkmark {
  position: absolute;
  top: 50%;
  left: 7px;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 1px solid rgba(13, 13, 13, 0.5);
  transform: translate(-50%, -50%);
}

.darkbody .search_location .checkmark {
  position: absolute;
  top: 50%;
  left: 7px;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 1px solid rgba(13, 13, 13, 0.5);
  transform: translate(-50%, -50%);
  background-color: #a6a6a6;
}

.search_location input:checked ~ .checkmark:after {
  display: block;
}

.search_location .checkmark:after {
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #f11b54;
  transform: translate(-50%, -50%);
}

.option_location .search_location input:checked ~ .checkmark {
  border: 1px solid rgb(184 184 184);
}

.option_location .search_location .checkmark:after {
  top: 7%;
  left: 38%;
  width: 6px;
  height: 10px;
  transform: translate(-50%, -50%);
  border: solid #892121;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 0;
  background: transparent;
}

.darkbody .option_location {
  /* color: #a6a6a6; */
}

.option_location .checkmark {
  border-radius: 0;
  background: #e4e4e459;
}
.Interested_in ul {
  transform: unset !important;
  position: unset !important;
  border: 0;
  padding: 0;
}

.darkbody .Interested_in ul {
  transform: unset !important;
  position: unset !important;
  border: 0;
  padding: 0;
  background-color: #1b1b1b;
  color: #a6a6a6;
}

.darkbody .Interested_in button {
  color: #fff;
}
.search_visible {
  visibility: hidden;
}
.container input:checked ~ .checkmark {
  border: 1px solid rgb(241 27 84);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.overflow_top {
  height: calc(100vh - 113px);
  overflow: auto;
  padding-right: 15px;
  padding-left: 10px;
}

/* scroll custom */
#style-2::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #e6e6e6;
}

#style-2::-webkit-scrollbar {
  width: 6px;
  background-color: #e6e6e6;
}

#style-2::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #e50e47;
}
#style-2::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
/* end */
.btn-filter {
  background-color: #e50e47;
  border-radius: 8px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #fff;
  margin-bottom: 10px;
  padding: 5px 20px;
  text-transform: uppercase;
  font-weight: 500;
  display: none;
}
.btn-cross {
  position: absolute;
  top: 10px;
  right: 10px;
}

/* General styles for the input */
.distance input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  margin: 5px -3px;
  background: transparent;
}

/* WebKit browsers (Chrome, Safari) */
.distance input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  background: #ddd;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.distance input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  background: #e50e47;
  border-radius: 50%;
  border: 2px solid #fff;
  cursor: pointer;
  margin-top: -8px;
}
.btn-meassage {
  width: 230px;
  height: 45px;
  border-radius: 6px;
  padding: 7px 20px;
}

.search_top .accordion-collapse.collapse {
  border-bottom: 0px !important;
}
.plan-w {
  max-width: 400px;
  width: 100%;
  margin: 20px auto;
  box-shadow: 0px 4px 6px rgba(13, 13, 13, 0.1);
  border: 1px solid rgb(245 196 209);
  border-radius: 16px;
  overflow: hidden;
  text-align: center;
}
.p-40 {
  padding: 31px 49px 84px 51px;
  position: relative;
}
.plan-w .title-head {
  width: 100%;
  display: block;
  font-size: 26px;
  text-align: center;
}
.plan-w ul {
  padding: 12px 0px 34px 55px;
}
.plan-w ul li {
  text-align: left;
  position: relative;
  font-size: 15px;
  padding-bottom: 10px;
  font-weight: 500;
}
.plan-w ul li:after {
  position: absolute;
  content: "\f00c";
  font-family: fontawesome;
  left: -25px;
  top: 0px;
  font-size: 16px;
  color: #f3809e;
}
.holder_inner {
  padding: 25px 20px !important;
}
.price-place2 {
  margin: 10px auto;
  height: 84px;
  width: 88px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 1px dashed #d31616;
  padding-top: 0px;
  font-size: 16px;
}
.price-place2 span {
  font-size: 26px;
}

/* ribbon */

/* bottom left*/
.ribbon-bottom-left {
  bottom: -10px;
  left: -10px;
}
.ribbon-bottom-left::before,
.ribbon-bottom-left::after {
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.ribbon-bottom-left::before {
  bottom: 0;
  right: 0;
}
.ribbon-bottom-left::after {
  top: 0;
  left: 0;
}
.ribbon-bottom-left span {
  right: -25px;
  bottom: 30px;
  transform: rotate(42deg);
}
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #f3809e;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #f3809e;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 16px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}
.key_f {
  font-size: 18px;
  font-weight: 500;
  margin-top: 25px;
  /* text-align: left; */
  /* padding-left: 44px; */
  color: #f3809e;
}
.current-plans {
  text-align: center;
  background: #fdeef2;
  padding: 20px 0px;
  margin-bottom: 33px;
  display: table;
  font-size: 22px;
  font-weight: 600;
  padding: 22px 123px;
  margin: 0 auto;
  text-align: center;
}
.p-95 {
  margin-top: 95px;
}

@media (max-width: 992px) {
  .img-profile-sec {
    height: 236px !important;
    width: 248px;
  }
  .btn-heart {
    height: 40px;
    width: 40px;
  }
  .btn-heart img {
    width: 25px;
    height: 25px;
  }
  .drop-ic .dropdown-toggle {
    height: 42px !important;
    width: 42px !important;
    font-size: 24px;
  }
  .right_profilename {
    margin-top: 5px !important;
  }
}

@media (max-width: 767px) {
  .search_top .reset_search button {
    padding: 7px 16px;
    font-size: 14px;
  }
  .p-40 {
    padding: 18px 20px 57px 20px;
    position: relative;
  }
  .p-95 {
    margin-top: 0px;
  }
  .drop-ic .dropdown-toggle {
    height: 32px !important;
    width: 32px !important;
    font-size: 20px;
  }
  .user_profile {
    padding-top: 35px;
  }
  .img-profile-sec {
    height: 283px;
    width: 100%;
    width: 241px;
    margin: auto;
  }
}
