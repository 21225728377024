.darkbody .privacypolicy span{
    color: #fff;
}

.privacypolicy u{
    text-decoration: none;
    font-weight: 600;
}
.privacypolicy strong{
    font-weight: 600;
}

.privacypolicy{
    padding: 20px 0px;
}
.privacypolicy h2, .privacypolicy h3{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 25px;
}

.privacypolicy h2 span{
    font-size: 20px !important;
}

.privacypolicy p{
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 10px;
}
