.about-sec{
    background-image: url("../../assets/images/about-img.jpg");
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    padding: 60px;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    background-attachment: fixed;
}
.bg-blur-b{
    background-color: #001025d9;
    padding: 30px;
    text-align: center;
}
.bg-blur-b h1{
    line-height: 1em;
    margin-bottom: 36px;
    color: #cda780;
    font-size: 52px;
    font-weight: 600;
}
.bg-blur-b p{
    color: #fff;
    font-size: 16px;
    margin-bottom: 20px;
}

.community-sec{
    background-color: #001025;
    padding: 80px 0px;
}
.community-sec h2{
    line-height: 1em;
    margin-bottom: 36px;
    color: #cda780;
    font-weight: 600;
    font-size: 38px;
    text-transform: uppercase;
    text-align: center;
}
.join-now{
    height: 54px;
    margin: 30px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 600;
    font-size: 20px;
    background: #E50E47;
    color: #fff;
    border-radius: 10px;
    text-decoration: none;
    font-size: 16px;
    padding: 18px 44px;
}

.join-now:hover{
    background: #E50E47;
    color: #fff;
    opacity: .9;
}

.commu-text{
    text-align: center;
    color: #fff;
    padding: 43px 0px;
}
.commu-text h3{
color: #fff;
font-size: 26px;
font-weight: 600;
margin-bottom: 20px;
}
.commu-text p {
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 0px;
}
.border-rr{
    border-right: 1px solid #cda780;
}

.msg-foundere{
    padding: 40px 0px;
}
.founder-ic{
    margin: 0 auto;
    height: 450px;
    width: 450px;
    border-radius: 50%;
    margin-bottom: 30px;
}
.founder-ic img{
    margin: 0 auto;
    height: 100%;
    width: 100%;
    border-radius: 50%;
}
.msg-foundere h4{
    color: #E50E47;
    font-size: 26px;
font-weight: 600;
margin-bottom: 20px;
}
.msg-foundere{
    text-align: center;
}

.msg-foundere p {
    color: #000;
    font-size: 16px;
    margin-bottom: 26px;
    line-height: 26px;
    font-weight: 500;
}

.worth-sec{
    background-image: url("../../assets/images/about3.jpg");
    background-repeat: no-repeat;
    width: 100%;
    height: 700px;
    padding: 60px;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-attachment: fixed;

}

.worth-t {
    text-align: center;
}
.worth-t h2 {
    color: #fff;
    font-size: 32px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 4px;
}
.worth-t .join-now{
    margin: 90px auto;
}
.worth-t p {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
}






@media (max-width: 992px) {
    .border-rr {
        border-right: 0;
    }
    .community-sec h2 {
        line-height: 33px;
        font-size: 29px;
    }
  }


  @media (max-width: 767px) {
    .msg-foundere h4 {
        font-size: 21px;
    }
    .founder-ic {
        height: 300px;
        width: 300px;
    }
    .bg-blur-b h1 {
        margin-bottom: 13px;
        font-size: 32px;
    }
    .about-sec {
        height: auto;
        padding: 29px 10px;
    }
    .worth-sec {
       
        height: auto;
        
    }
    .worth-t .join-now {
        margin: 25px auto;
    }
    .worth-t h2 {
        font-size: 20px;
    }
    .worth-t p {
        font-size: 15px;
    }
    .msg-foundere p {
        font-size: 14px;
        margin-bottom: 22px;
        line-height: 21px;
    }
    .community-sec h2 {
        line-height: 33px;
        font-size: 22px;
    }
    .community-sec {
        padding: 46px 0px;
    }
    .commu-text {
        padding: 26px 0px;
    }



  }








