.login-modal {
    background: linear-gradient(124.61deg, rgba(39, 39, 39, 0.1) -4.36%, rgba(39, 39, 39, 0.05) 110.11%);
    backdrop-filter: blur(3.5px);
}

.login-modal .modal-content {
    background: #FFFFFF;
    border: 2px solid rgba(13, 13, 13, 0.1);
    padding: 26px 36px;
    border-radius: 12px;
}
.darkbody .login-modal .modal-content {
    background: #1b1b1b;
    border: 2px solid rgb(165 161 161 / 30%);
    padding: 26px 36px;
    border-radius: 12px;
}
.darkbody .resend-otps, .darkbody .btn_back{
    color: #fff;
}


.darkbody .password-toggle-btn{
    color: #949494;
}
.logo-modal a {
    margin-right: auto;
    float: left;
    position: absolute;
    left: 0;
    top: 16px;
    color: #8f8f8f;
}
.darkbody .logo-modal a {
    margin-right: auto;
    float: left;
    position: absolute;
    left: 0;
    top: 16px;
    color: #fff;
}

.logo-modal {
    width: 100%;
    text-align: center;
    position: relative;
    color: #868686;
}
.darkbody .logo-modal{
    width: 100%;
    text-align: center;
    position: relative;
    color: #fff;
}

.logo-modal h5 {
    font-size: 16px;
    color: #E50E47;
}

.Choose_Username button {
    margin-top: 31px;
    background: #E50E47;
    border: 1px solid rgba(13, 13, 13, 0.1);
    border-radius: 12px;
    padding: 13px 36px;
    width: 100%;
}


.Choose_Username .valid-feedback {
    font-weight: 500;
}

.Choose_Username h6,
.photo_preview h6 {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #A3A3A3;
}

.or-line span {
    margin: 0 auto;
    display: table;
    background: #fff;
    padding: 0 14px;
    font-size: 15px;
    color: #a6a6a6;
    position: relative;
    z-index: 1;
}
.or-line span {
    margin: 0 auto;
    display: table;
    background: #fff;
    padding: 0 14px;
    font-size: 15px;
    color: #a6a6a6;
    position: relative;
    z-index: 1;
}
.darkbody .or-line span {
    margin: 0 auto;
    display: table;
    background: #1b1b1b;
    padding: 0 14px;
    font-size: 15px;
    color: #fff;
    position: relative;
    z-index: 1;
}

.or-line hr {
    margin: -11px 0 34px;
    color: #868686;
}

.darkbody .or-line hr {
    margin: -11px 0 34px;
    color: #fff;

}

.goole-social a {
    font-weight: 600;
    font-size: 16px;
    background: #F2F2F2;
    border: 1px solid rgba(13, 13, 13, 0.1);
    border-radius: 12px;
    padding: 14px 10px;
    margin: 0 auto;
    display: table;
    color: #3E66BA;
    margin-bottom: 14px;
    width: 100%;
    max-width: 65%;
    text-decoration: none;
}

.darkbody .goole-social a {
    font-weight: 600;
    font-size: 16px;
    background:#1b1b1b;
    border: 1px solid rgba(13, 13, 13, 0.1);
    border-radius: 12px;
    padding: 14px 10px;
    margin: 0 auto;
    display: table;
    color: #3E66BA;
    border: #3E66BA solid 0.2px;
    margin-bottom: 14px;
    width: 100%;
    max-width: 65%;
    text-decoration: none;
}

.about_useredit textarea {
    resize: none;
    height: 150px !important;
    min-height: unset !important;
    overflow: hidden;
    margin-bottom: 8px;
}

.login-form input {
    width: 100%;
   
}

.darkbody .login-form input {
    width: 100%;
    background-color: #1b1b1b;
    
    
}

.login-form .mb-3 {
    position: relative;
}

.login-form button {
    background: #F11B54;
    color: #fff;
    border: 0;
    font-weight: 600;
}

.goole-social a img {
    margin-right: 13px;
}

.goole-social a.google-cont {
    color: #EC594C;
    margin-bottom: 30px;
}

.darkbody .goole-social a.google-cont {
    color: #EC594C;
    border: #EC594C solid 0.2px;
    
}

form label {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #0D0D0D;
}
.darkbody form label {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #fff;
}

.Edit_your_info input {
    /* height: 48px !important; */
    font-weight: 500;
    font-size: 14px !important;
}

.show-pass {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 14px;
    color: #868686;
    text-decoration: underline;
}

.Edit_your_info input::placeholder {
    color: #000 !important
}

.logo-modal .close {
    position: absolute;
    right: -10px;
    top: 15px;
}

.PhoneInputInput {
    padding: 14px 10px;
    background: #F2F2F2;
    border: 1px solid rgba(13, 13, 13, 0.1);
    border-radius: 12px;
}
.sign-u{
    color: #F11B54;
    font-weight: 600;
    text-decoration: none;
}
.google_b{
    background: #205ab9 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin-bottom: 15px;
}



@media (min-width: 992px) {

    .login-modal .modal-lg {
        max-width: 680px;
    }
}


@media (max-width:540px) {
     .goole-social a {
        font-size: 14px;
        max-width: 100%;
    }
 

}