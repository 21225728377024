.banner-left::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: -90px;
  background-image: url("../../assets/images/arrow-black.png");
  background-repeat: no-repeat;
  width: 290px;
  height: 115px;
  background-size: 100%;
  background-repeat: no-repeat;
}

.banner-left {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
}

.darkbody .ohd p,.darkbody .ohd h3{
  background-color: #2E2E2E !important;
  color: #fff !important;
} 

.ohd p, .ohd h3{
  background-color: #fff !important;
  color: #000 !important;

}

.banner-left a {
  background: #E50E47;
  color: #fff;
  border-radius: 15px;
  text-decoration: none;
  font-size: 16px;
  padding: 18px 52px;
  float: left;
  margin-top: 30px;
}

.banner-left h1 {
  font-family: 'Glitten';
  font-size: 60px;
  color: #000;
}

.darkbody .banner-left h1 {
  font-family: 'Glitten';
  font-size: 60px;
  color: #fff;
}

.banner-right img {
  width: 100%;
}

.happen-sec {
  padding-bottom: 100px;
}

.heading-60 {
  font-family: 'Glitten';
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 70px;
  text-align: center;
  color: #000;
  margin-bottom: 56px;
}
.darkbody .heading-60
{
  color: #fff;
}

/*  */


.bold{
  font-weight: 900;
}
.outer-happen {
  cursor: pointer;
  min-height: 436px;
  /* background: rgba(217, 217, 217, .1); */
  padding: 48px 15px;
  text-align: center;
  position: relative;
  border-radius: 16px;
  margin-bottom: 20px;
  background: #FFFFFF;
  box-shadow: 0px 7px 18px -3px rgba(0, 0, 0, 0.11);
  border-radius: 16px;
}

.darkbody .outer-happen{
  min-height: 436px;
  cursor: pointer;
  /* background: rgba(217, 217, 217, .1); */
  padding: 48px 15px;
  text-align: center;
  position: relative;
  border-radius: 16px;
  margin-bottom: 20px;
  background: #2E2E2E;
  box-shadow: 0px 7px 18px -3px rgba(0, 0, 0, 0.11);
  border-radius: 16px;
}

.darkbody .outer-happen h3, .darkbody .outer-happen h3 p{
  /* background: #2e2e2e; */
  color: #fff !important;
 }

 .outer-happen h3, .outer-happen h3 p{
  /* background: #2e2e2e; */
  color: #000 !important;
 }

.outer-happen::after {
  position: absolute;
  content: '';
  left: 50%;
  bottom: 40px;
  border-bottom: 2px solid #E52525;
  width: 80px;
  transform: translate(-50%, 0%);
}

.outer-happen h3 span {
  font-size: 18px;
}

.outer-happen h3 {
  font-family: 'Rufina';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #000;
  margin: 44px 0px;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.outer-happen p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #000;
  opacity: 0.8;
  margin-bottom: 37px;
  position: relative;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 82px;
  width: 80%;
  margin: 0 auto;
}

.seeker {
  margin-bottom: 146px;
}

.bg-seeker {
  background-image: url("../../assets/images/bg-seeker.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 66px 20px;
  border-radius: 20px;
}

.bg-seeker h3 {
  font-family: 'Glitten';
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 70px;
  text-align: center;
  color: #FFFFFF;
  width: 80%;
  margin: 0 auto;
  display: block;
  text-transform: uppercase;
  background-color: initial;
}

.bg-seeker p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 29px;
  text-align: center;
  color: #FFFFFF;
  padding: 40px 0px;
  background-color: initial;
}

.start-seek {
  width: 289px;
  height: 64px;
  background: #FFFFFF;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #E50E47;
  margin: 0 auto;
  text-decoration: none;
}

.start-seek:hover {
  background: #E50E47;
  color: #FFFFFF;
}

.testimonials {
  padding-bottom: 143px;
}

.client-say h4 {
  font-family: 'Glitten';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 48px;
  letter-spacing: 0.04em;
  color: #000;
  position: relative;
}

.darkbody .client-say h4 {
  font-family: 'Glitten';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 48px;
  letter-spacing: 0.04em;
  color: #fff;
  position: relative;
}

.client-say h4::after {
  position: absolute;
  content: '"';
  left: 0%;
  width: 114px;
  transform: translate(0%, 0%);
  top: 18px;
  font-size: 173px;
  color: #ED6767;
}

.client-slider button {
  background-color: rgba(237, 103, 103, .2);
  border: 0px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 0px;
  overflow: hidden;
  font-size: 0;
  z-index: 99;
}
.client-slider .slick-next:before{
  content:'\f054';
  font-family: fontAwesome;
}

.client-slider .slick-prev:before{
  content:'\f053';
  font-family: fontAwesome;
}

.test-outer {
  width: 410px;
  min-height: 199px;
  border: 1px solid rgba(255, 255, 255, .1);
  filter: drop-shadow(0px 4px 24px rgba(85, 85, 85, 0.07));
  border-radius: 16px;
  padding: 25px;
  margin: 10px;
}

.test-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.test-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.card-text {
  display: flex;
  align-items: center;
}

.test-name {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 17.9368px;
  line-height: 18px;
  letter-spacing: 0.04em;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 40px);
  padding-left: 15px;
}

.darkbody .test-name {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 17.9368px;
  line-height: 18px;
  letter-spacing: 0.04em;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 40px);
  padding-left: 15px;
}

.test-name span {
  font-size: 22px;
  color: #FDB913;
}

.join-c {
  width: 100%;
  min-height: 363px;
  background: rgba(217, 217, 217, .1);
  position: relative;
  border-radius: 23px;
  padding: 48px 35px 48px 160px;
}

.join-c h3 {
  font-family: 'Glitten';
  font-style: normal;
  font-weight: 400;
  font-size: 40.9091px;
  line-height: 50px;
  text-transform: capitalize;
  color: #000;
}

.join-c h3 span {
  color: #ED6767;
}

.join-c p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  padding: 26px 0px;
  color: #000;
  opacity: 0.8;
}

.mob-pos {
  position: absolute;
}

.mob-pos {
  position: absolute;
  left: -186px;
  bottom: -50px;
}

.hand-sec {
  width: 100%;
  overflow: hidden;
  margin-bottom: 140px;
  background: rgba(229, 14, 71, .05);
  padding: 66px 0px;
}

.darkbody .hand-sec {
  width: 100%;
  overflow: hidden;
  margin-bottom: 140px;
  background: #2b2b2b;
  padding: 66px 0px;
}
.darkbody .hand-sec h1,.darkbody .hand-sec h3,.darkbody .hand-sec p {
  background: #3d3d3d;
}

.footer-sec {
  background-color: #fff;
  padding: 40px 0px;
  box-shadow: 0px -4px 10px -6px rgba(0, 0, 0, 0.15);
  /* border-top: 1px solid #eeecec; */
  
}

.darkbody .footer-sec {
  background-color: #000;
  padding: 40px 0px;
  box-shadow: 0px -4px 10px -6px rgba(0, 0, 0, 0.15);
}

.footer-sec i {
  color: #000;
  padding: 3px;
}

.footer-logo {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20.243px;
  line-height: 24px;
  text-align: center;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.darkbody .footer-logo {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20.243px;
  line-height: 24px;
  text-align: center;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.l-sec {
  padding-right: 15px;
}

/* s-icon a .fa */
.s-icon svg {
  color: #000;
  font-size: 20px;
  z-index: 999;
  padding: 2px 11px;
}

.darkbody .s-icon svg {
  color: #fff;
  font-size: 20px;
  z-index: 999;
  padding: 2px 11px;
}

.s-icon {
  padding-left: 18px;
  border-left: 1px solid #000;
}


ul.nav.nav-footer {
  justify-content: center;
  margin-top: 50px;
}
.ul.nav.nav-footer.inner-footer {
  margin-top: 20px !important;
}
.nav.nav-footer li a {
  color: #000;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  padding: 7px 36px;
  position: relative;
  text-decoration: none;
  cursor: pointer;
}

.darkbody .nav.nav-footer li a {
  color: #fff;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  padding: 7px 36px;
  position: relative;
  text-decoration: none;
  cursor: pointer;

}

.nav.nav-footer li {
  position: relative;
}

.nav.nav-footer li::after {
  content: "";
  position: absolute;
  right: 0px;
  height: 13px;
  width: 1px;
  background-color: #000;
  top: 22%;
}

.nav.nav-footer li:last-child::after {
  width: 0px;
}

.foot-p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 29px;
  text-align: center;
  padding: 30px 0px;
  color: #000;
  background: #fff;
}

.darkbody .foot-p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 29px;
  text-align: center;
  padding: 30px 0px;
  background: #000;
}

.powered-by {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000;
  background-color: #fff;
}

.darkbody .powered-by {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #fff;
  background-color: #000;
}

.test-p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;
  color: #000;
  opacity: 0.8;
  margin-top: 20px;
}

.dark-body .test-p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;
  color: #000;
  opacity: 0.8;
  margin-top: 20px;
}
.dark-body .bg_bordered {
  border-bottom: 1px solid rgb(255 253 253 / 10%);
}
.dark-body input{
  background-color: transparent;
}
.darkbody .info-accordion-button {
  background-color: #393939;
}
.dark-body .collapse-edit .accordion-collapse.collapse {
  border-bottom: 1px solid #a19f9f !important;
  background: #393939;
  border: 1px solid #808080;
}
.darkbody .text_change {
  background-color: #0000009c !important;
}
.dark-body .bg_bordered {
  border-bottom: 1px solid rgb(255 255 255 / 44%);
 
}
.me-2{
  width: 70px;
}
.me-2-header{
  width: 80px;
}
.banner {
  padding-top: 93px;
}
.top-50 {
  top: 25px !important;
}
/* new */
input:focus{
  box-shadow: none;
  outline: 0px;
}

.Edit_your_info .modal-header{
  padding: 0px;
}

.ph-input .PhoneInputCountry{
    position: absolute;
    left: 10px;
    top: 40px;
}
.ph-input input{padding-left: 50px;}
.ph-input{
  position: relative;
}
.login-form input::placeholder{
  font-weight: 400;
  color: #ccc;
  font-size: 14px;
}
.top-space {
  padding-top: 93px !important;
  min-height: calc(100vh - 158px);
}
.main-sec.search_page{
  padding-top: 115px;
}


.flex-column li .btn {
  width: 100% !important;
  text-align: left !important;
  background-color: transparent;
  padding: 13px 15px;
  border-bottom: 1px solid #ebe8e8 !important;
}
.flex-column li .btn.active{
  background-color:#fff !important;
  color: #000 !important;
}
.flex-column li:hover .btn{background-color:#fff !important;}
.common-texts{
  padding: 15px 12px;
}
.common-texts h2, .common-texts h3{
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 15px;
  text-decoration: none;
}
.common-texts h2 u, .common-texts h3 u{
  text-decoration: none;
}
.common-texts u{
  text-decoration: none;
  font-weight: 600;
}
.common-texts p{
  line-height: 24px;
  font-size: 16px;
  margin-bottom: 10px;
}
.common-texts p u, .common-texts p strong {font-weight: 600;}
.common-texts h2 span u{
  font-size: 28px !important;
}
.sidebar-inner{
  height: 100vh;
}
.common-texts h1{
  font-size: 26px;
  font-weight: 600;
}
.img-users{
  height: 170px;
  width: 150px;
}
.img-users img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.flex-row{
  display: flex;
}
.l-150{
  width: 150px;
}
.r-auto{
  width: calc(100% - 150px);
  padding-left: 10px;
}
.phon-edit input{
  padding: 0px;
  border: none;
  padding-left: 10px;
}
.h-auto{
  height: auto !important;
}
.btn-primary{
    background: #E50E47 !important;
    border-color: #E50E47 !important;
    color: #fff;
    border-radius: 10px;
    text-decoration: none;
    font-size: 16px;
    padding: 10px 41px;
    float: left;
    margin-top: 13px;
}
.btn-primary:hover{
 background-color: #E50E47;
 border-color:#E50E47;
opacity: .9;}

.pull-right{
  float: right;
}
.photo-text h2{
font-size: 22px;
text-align: center;
}
.form-sec.photo-text {
  min-height: 109px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.close-pos{
  position: absolute;
  right: 15px;
  top: 15px;
}
.selected {
  background: #f2f2f2;
  border: 2px solid #d61548 !important;
}
.drop-ic .dropdown-toggle::after{border: 0px;margin-left: 0px;}
.drop-ic .dropdown-toggle {
  border-radius: 50% !important;
  height: 50px !important;
  width: 49px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  padding: 0px;
  text-align: center;
  padding-right: 0px;
  margin-right: 0px;
}
.drop-ic .dropdown-menu li a{
  font-size: 14px;
}
.drop-ic .dropdown-menu{
  box-shadow: 0px 4px 6px rgba(0, 0, 0, .1);
  border: none;
}

.btn-heart{
  position: absolute;
  right: 10px;
  bottom: 20px;
  background: rgba(0, 0, 0, .3);
    border: none;
    color: rgb(255, 255, 255);
    cursor: pointer;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, .1);
}
.btn-heart:hover{  background: rgba(0, 0, 0, .3);}

.justify-center{
  justify-content: center;
}
.p-bg-none {
  background-color: transparent !important;
  padding: 0px !important;
  font-size: 18px !important;
  text-align: center;
  line-height: 27px;
  padding: 20px 0px !important;
}
.footer-btn{
  margin: 0px;
  gap: 10px;
  display: flex;
}
.btn-gry{
  background-color: #ccc;
  color: #fff;
  border-color: #bfbfbf;
}

.btn-gry:hover{
  background-color: #bfbfbf;
  color: #fff;
  border-color: #bfbfbf;
}
.f-18{
  font-size: 18px;
}
.modal-content {
  border: none;
  box-shadow: 0px 4px 6px rgba(0,0,0,.1);
  border-radius: 20px;
}
.edit-modal textarea{
  height: auto !important;
}
.proof-input{
  overflow: hidden;
  margin-bottom: 20px;
}
.eye-pos {
  position: absolute;
  right: 11px;
  top: 11px;
  width: 20px;
}
.btn_back {
  background-color: transparent;
  border: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  font-weight: 500;
  text-align: center;
  margin: auto;
  text-transform: capitalize;
}
.btn_back:hover {
  color: #E50E47;
}




@media (max-width:991px) {
  .banner-left h1 {
    font-size: 37px;
    line-height: 48px;
  }

  .banner-left::before {
    right: -46px;
    bottom: -122px;
    width: 168px;
    height: 115px;
  }

  .mission-sec {
    padding: 40px 0px 70px 0px;
  }

  .outer-happen p {
    font-size: 16px;
    width: 100%;
  }

  .outer-happen h3 {
    font-size: 22px;
    margin: 36px 0px;
  }

  .bg-seeker h3 {
    font-size: 48px;
    line-height: 70px;
    width: 100%;
  }

  .bg-seeker h3 {
    font-size: 30px;
    line-height: 53px;
  }

  .testimonials {
    padding-bottom: 80px;
  }

  .client-say h4 {
    font-size: 48px;
    line-height: 48px;
    margin-bottom: 22px;
  }

  .hand-sec {
    padding-bottom: 50px;
  }

  .join-c {
    width: 100%;
    min-height: 363px;
    background: rgba(217, 217, 217, .1);
    position: relative;
    border-radius: 23px;
    padding: 20px 20px 20px 47px;
  }

  .join-c h3 {
    font-size: 20.9091px;
    line-height: 32px;
  }

  .join-c {
    min-height: 290px;
    padding: 20px 20px 20px 103px;
  }

  .mob-pos {
    position: absolute;
    left: -88px;
    bottom: 46px;
    width: 180px;
  }
}

@media (max-width:767px) {
  .mob-chat{
    border: 1px solid rgba(0, 0, 0, .1);
  }
  .l-sec .me-2 {
    width: 45px;
}
  .main-sec.search_page{
    padding-top: 90px;
  }
  .client-slider .slick-prev {
    left: 0px;
}
.client-slider .slick-next {
  right: 0px;
}
  .hand-sec {
    margin-bottom: 50px;
  }
  .seeker {
    margin-bottom: 90px;
  }
  .bg-seeker p {
    font-size: 16px;
    line-height: 26px;
    padding: 30px 0px;
}
  .bg-seeker {
    padding: 36px 14px;
    border-radius: 20px;
}
  .heading-60 {
    font-size: 35px;
    margin-bottom: 20px;
}

  .mission-sec h2 {
    font-size: 28px;
    margin: 30px 0px 10px 0px !important;
}
  .banner-left a {
    border-radius: 8px;
    font-size: 15px;
    padding: 12px 25px;
    margin-top: 30px;
}
  .me-2-header{
    width: 60px;
  }
  .banner-left a {
    margin-top: 26px;
    margin-bottom: 20px;
  }

  .banner-left h1 {
    font-size: 26px;
    line-height: 46px;
  }

  .banner-left::before {
    display: none;
  }

  .mission-sec h2 {
    font-size: 30px;
    margin-bottom: 0px;
  }

  .mission-sec p {
    font-size: 14px;
    line-height: 25px;
  }

  .mission-sec {
    padding: 20px 0px 40px 0px;
  }

  .happen-sec {
    padding-bottom: 50px;
  }

  .outer-happen h3 {
    font-size: 20px;
    line-height: 30px;
    margin: 29px 0px;
  }

  .outer-happen p {
    font-size: 14px;
    line-height: 27px;
    min-height: 60px;
  }

  .outer-happen {
    margin-bottom: 15px;
    min-height: 372px;
  }

  .testimonials {
    padding-bottom: 38px;
  }

  .client-say h4 {
    font-size: 23px;
    margin-bottom: 10px;
  }
  .client-say h4::after {
    width: 92px;
    top: 18px;
    font-size: 132px;
}
  .test-p {
    font-size: 12px;
  }

  .join-c h3 {
    font-size: 28px;
    line-height: 45px;
    margin-top: 20px;
  }

  .join-c {
    padding: 30px 30px 30px 30px;
    margin: 20px 0px;
  }

  .mob-pos {
    position: initial;
  }

  .r-hand {
    width: auto;
  }

  .footer-logo {
    font-size: 16.243px;
  }

  .s-icon a .fa {
    font-size: 15px;
    padding: 2px 7px;
  }

  .nav.nav-footer li a {
    font-size: 14px;
    padding: 7px 12px;
    cursor: pointer;
  }

  ul.nav.nav-footer {
    margin-top: 0px !important;
    padding-top: 5px !important;
    padding-bottom: 0px !important;

  }
  .footer-sec {
    padding: 20px 0px;
}
  .foot-p {
    font-size: 14px;
    line-height: 21px;
    padding: 17px 0px;
  }

  .powered-by {
    font-size: 12px;
  }
}



