.search_page .list-img-size {
	height: unset;
  position: relative;
}
.search_page{
  min-height: 100vh;
}

.search_page .n-adress {
	position: relative;
	background: #F0F0F0;
	box-shadow: 0px 2px 16px -3px rgba(0, 0, 0, 0.2);
	border-bottom-right-radius: 8px;
	padding: 10px;
	border-bottom-left-radius: 8px;
	bottom: 0;
	left: 0;
  z-index: 9;
}

.search_page .n-adress img {
	position: absolute;
	top: -20px;
	right: 8px;
	width: 50px;
	height: 50px;
}

.search_page .n-adress h4 {
	color: #0D0D0D;
  
}

.search_page .n-adress p {
	color: #0d0d0d7d;
	padding: 4px 0;
}

.search_page .n-adress h6 {
	font-size: 10px;
	color: #0D0D0D;
	font-weight: 400;
}

.listing-sec {
	padding: 70px 0px;
}

.Interests_fav {
  background: #f2f2f2;
  border-radius: 12px;
  padding: 30px !important;
}

.darkbody .Interests_fav {
  background: #262626;
  border-radius: 12px;
  padding: 30px !important;
  margin-top: 20px;
}

.Interests_fav .interesttabs {
  justify-content: space-between;
  align-items: center;
}

.Interests_fav select {
  color: #e50e47;
  border-radius: 8px;
}

.Interests_fav .interesttabs li {
  padding: 12px 22px;
}

.Interests_fav .interesttabs li:nth-child(1) {
  padding-left: 0;
}

.Interests_fav .interesttabs li.active label {
  color: #F11B54;
  border-bottom: 2px solid;
  opacity: 1;
}


.interesttabs .tabs {
  display: flex;
  align-items: stretch;
  list-style: none;
  padding: 0;
}

.interesttabs .tab>label {
  display: block;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #0D0D0D;
  opacity: 0.3;
}

.darkbody .interesttabs .tab>label {
  display: block;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: white;
  opacity: 0.3;
}

.inner_interest .inner_content span:nth-child(1) .profile_img {
  width: 80px;
}
.profile-size{
  width: 80px;
  height: 90px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.profile-size .profile_img{
width: 100%;
height: 100%;
object-fit: contain;
}
.inner_interest .inner_content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
}

/* .inner_interest .tab-content {
  display: none;
} */

.inner_interest .inner_content span:nth-child(1) {
  display: flex;
  align-items: center;
  gap: 30px;
}

.inner_interest .inner_content .deatslist_name h4 {
  color: #000;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.inner_interest .inner_content .deatslist_name h5 {
  font-weight: 400;
  font-size: 12px;
  color: #0D0D0D;
  padding-top: 5px;
}

.inner_interest .inner_content .deatslist_name p {
  font-weight: 400;
  font-size: 12px;
  color: #0D0D0D;
  opacity: 0.5;
  padding-top: 8px;
}

.inner_interest .inner_content .deatslist_name h6 {
  font-weight: 400;
  font-size: 14px;
  padding-top: 10px;
  color: #0D0D0D;
}

.darkbody .inner_interest .inner_content .deatslist_name h4 {
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.darkbody .inner_interest .inner_content .deatslist_name h5 {
  font-weight: 400;
  font-size: 12px;
  color: #fff;
  padding-top: 5px;
}

.darkbody .inner_interest .inner_content .deatslist_name p {
  font-weight: 400;
  font-size: 12px;
  color: #fff;
  opacity: 0.5;
  padding-top: 8px;
}

.darkbody .inner_interest .inner_content .deatslist_name h6 {
  font-weight: 400;
  font-size: 14px;
  padding-top: 10px;
  color: #fff;
}

.inner_interest .inner_content span .inner_adventur span:nth-child(1) {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #F11B54;
}
.btn.p-2{
  padding-bottom: 0px !important;
}

.inner_interest .inner_content span .inner_adventur span:nth-child(1) img {
  padding-left: 0;
}

.inner_interest .inner_content span .inner_adventur span {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #0000007d;
  gap: 0;
}

.darkbody .inner_interest .inner_content span .inner_adventur span {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
  gap: 0;
}

.inner_interest .inner_content .deatslist_name .inner_adventur {
  display: flex;
}

.inner_interest .inner_content span:nth-child(1) .health_body {
  margin-left: 40px;
}

.inner_interest .inner_content .health_body table th {
  font-weight: 500;
  font-size: 12px;
  color: #0D0D0D;
  padding: 5px 11px;
}

.inner_interest .inner_content .health_body table td {
  font-weight: 400;
  font-size: 12px;
  color: #0D0D0D;
  padding: 5px 11px;
}
/* html{
  background: #1b1b1b;
} */

.inner_interest .inner_content span:nth-child(2) {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #0d0d0da8;
}

.tabbed {
  overflow-x: hidden;
}

.tabbed [type="radio"] {
  display: none;
}

.tabbed [type="radio"]:nth-of-type(1):checked~.tab-content:nth-of-type(1),
.tabbed [type="radio"]:nth-of-type(2):checked~.tab-content:nth-of-type(2),
.tabbed [type="radio"]:nth-of-type(3):checked~.tab-content:nth-of-type(3),
.tabbed [type="radio"]:nth-of-type(4):checked~.tab-content:nth-of-type(4) {
  display: block;
}

.member-loading {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #F11B54;
}
.inner_interest .inner_content span .inner_adventur span:nth-child(1) {
  display: flex;
  align-items: center;
  gap: 10px;
}
.inner_interest .inner_content span .inner_adventur span{
  display: flex;
  align-items: center;
  gap: 10px;
}
.btn.p-2 {
  padding-bottom: 0px !important;
  padding-right: 0px !important;
  display: flex;
  align-items: center;
  padding-top: 0px !important;
}
.inner_adventur{
  margin-top: 6px;
}

@media (max-width:767px) {
  .inner_interest .inner_content {
    flex-direction: row !important;
    width: 100%;
    align-items: flex-start;
    gap: 15px;
    margin-bottom: 10px;
}

.inner_adventur .btn svg{
margin-left: 0px !important;
}
.inner_adventur .btn.p-2{
  padding: 0px !important;
    
}
  .inner_adventur{
    margin-top: 10px;
    align-items: center;
    display: flex;
    gap: 20px;
  }
  .Interests_fav {
    padding: 15px !important;
  }
	.inner_interest .inner_content span:nth-child(2) {
    margin-top: 0px;
  }

	
  .inner_interest .inner_content {
    flex-direction: column;
  }
  .tabs_member li button {
    font-size: 14px;
    padding: 8px 12px;
}
  .inner_interest .inner_content span:nth-child(1) {
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
    gap: 15px;
  }
  .inner_adventur span{
    padding-bottom: 6px;
  }
	.inner_interest .inner_content span:nth-child(1) .health_body {
    margin-left: 0;
  }
	
.inner_interest .inner_content .deatslist_name p {
  padding-top: 4px;
}
  #minutes_three {
    width: 100%;
    padding-top: 20px;
    text-align: center;
  }

	.deatslist_name {
    text-align: left;
  }
  .inner_interest .inner_content .deatslist_name .inner_adventur {
    display: block;
}
.inner_adventur span img, .inner_adventur span svg{
  padding-bottom: 0px !important;
  padding-right: 7px !important;
}


	
  .Interests_fav .interesttabs {
    flex-direction: column;
    gap: 10px;
  }

	.interesttabs .tab>label {
    font-size: 13px;
  }
  .Interests_fav .interesttabs li {
    padding: 12px 11px;
}
	.listing-sec {
    padding: 10px;
  }
}