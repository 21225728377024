header.headser-mian ul.navbar-nav {
  margin: 0 auto;
}

header.headser-mian ul.navbar-nav li a {
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
}
.darkbody header.headser-mian ul.navbar-nav li a {
  color: #a6a6a6;
  font-size: 16px;
}

header.headser-mian ul.navbar-nav li {
  margin: 0 15px;
  cursor: pointer;
}

header.headser-mian ul.navbar-nav li.active a {
  color: #e50e47;
  font-weight: 600;
}

header.headser-mian {
  padding: 10px 0 10px;
  position: fixed;
  background-color: #fff;
  width: 100%;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #e7e7e7;
  z-index: 99;
}

.darkbody header.loginHeader {
  background-color: #262626;
  padding: 0;
}
a.navbar-brand {
  padding: 0px;
}
.navbar-width {
  padding: 0px;
}
.second-header.headser-mian {
  padding: 0px 0 0px;
}

header.headser-mian.second-header ul.navbar-nav li.active a {
  /* background: rgba(229, 14, 71, .15); */
  background: rgba(0, 0, 0, 0.06);
}

header.headser-mian.second-header a.navbar-brand {
  padding: 0px;
}

header.headser-mian.second-header .navbar {
  padding: 0px;
}

header.headser-mian.second-header ul.navbar-nav li a {
  padding: 20px 10px;
}

.login p {
  background: #d9d9d91a;
  color: #e50e47 !important;
  font-size: 16px;
  text-decoration: none;
  /* border: 1px solid #E50E47; */
  border-radius: 5px;
}

.mob-show {
  display: none;
}

.second-nav li a {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.login a {
  background: #d9d9d91a;
  /* color: #E50E47 !important; */
  font-size: 16px;
  text-decoration: none;
  /* padding: 14px 30px; */
  /* border: 1px solid #E50E47; */
  border-radius: 5px;
}

.add-vibe {
  background: #d9d9d91a;
  color: #e50e47 !important;
  font-size: 16px;
  text-decoration: none;
  padding: 14px 30px;
  border: 1px solid #e50e47;
  border-radius: 5px;
}

.drop-btn .btn-top {
  /* font-style: italic; */
  font-weight: 400;
  font-size: 16.805px;
  line-height: 20px;
  background-color: transparent;
  /* opacity: 0.5; */
  display: flex;
  align-items: center;
}

.darkbody .drop-btn .btn-top {
  /* font-style: italic; */
  font-weight: 400;
  font-size: 16.805px;
  line-height: 20px;
  background-color: transparent;
  /* opacity: 0.5; */
  display: flex;
  align-items: center;
  color: #fff;
}

.btn-top span {
  padding-left: 10px;
}

.profile-wid {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
}

.profile-wid img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.btn-name {
  max-width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.darkbody .btn-name {
  max-width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #a6a6a6;
}
.darkbody hr {
  color: #a6a6a6;
}

.top-drop-menu {
  width: 332px;
  min-height: 280px;
  border: 0.5px solid rgba(255, 255, 255, 0.3);
  padding: 0px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}

.top-drop-menu a {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #0d0d0d;
  padding: 12px 19px;
}

.top-drop-menu a:hover {
  background-color: transparent;
  color: #000;
}

.top-drop-menu li.p-16 i {
  padding-right: 15px;
  color: rgba(0, 0, 0, 0.2);
}

/* .darkbody .p-16{
    color: green;
} */
.darkbody .dark-drop {
  background: #353434;
}

.dark-drop {
  margin-left: -109px;
}

.darkbody .dark-drop h3 {
  color: #fff;
}
.darkbody .dark-drop button {
  background: #1b1b1b;
  color: #fff;
}
.darkbody .dark-drop a {
  color: #fff;
}

.top-drop-menu li.active a {
  color: #e50e47;
}

.top-drop-menu .dropdown-item.active,
.dropdown-item:active {
  background-color: transparent;
}

.top-drop-menu .dropdown-item:focus,
.dropdown-item:hover {
  background-color: transparent;
}

.pad-16 {
  padding: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.profile-change {
  display: flex;
  align-items: center;
  cursor: default;
}

.img-sec {
  width: 64px;
  height: 77px;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}

.img-sec img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bg-trans {
  background: rgba(228, 228, 228, 1);
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  color: #f11b54;
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 7px;
  font-weight: 500;
}

.file-pos {
  width: 100%;
  height: 100%;
  font-size: 100px;
  position: absolute;
  left: 0px;
  opacity: 0;
}

.text-sec {
  width: calc(100% - 65px);
  padding-left: 16px;
}

.text-sec h3 {
  font-weight: 500;
  font-size: 16.805px;
  line-height: 20px;
  color: #000;
}

.text-sec p {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #e50e47;
}

.btn-c {
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.08);
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #e50e47;
  border-radius: 6px;
  text-transform: uppercase;
  margin-top: 10px;
}

.btn-c:hover {
  color: #e50e47;
}

.edit-profile-b  a{
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 15px;
  color: #000;
  text-transform: capitalize;
  width: 100%;
  text-decoration: none;
  padding: 0px;
  background-color: transparent;
}
.edit-profile-b:hover a{
  /* background-color: #e50e47; */
  color: #fff !important;
}


.edit-profile-b  {
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 15px;
  color: #0d0d0d;
  text-transform: capitalize;
  width: 100%;
  text-decoration: none;
}
.edit-profile-b:hover {
  background-color: #e50e47;
  color: #fff !important;
}

.navbar-width {
  width: 100%;
}

.dropdown-item svg {
  padding-right: 10px;
}

@media (max-width: 991px) {
  .headser-mian .navbar-toggler {
    font-size:28px;
    padding: 0px;
  }

  header.headser-mian ul.navbar-nav {
    margin: 50px auto;
  }

  .mob-show {
    display: block;
  }

  .collapse-mob {
    display: block;
    height: 100vh;
  }
.sidebar{border: none;
font-size: 16px;}

.sidebar:focus{border: none;
  outline: none !important;
box-shadow: none;}


.nav-header .collapse-mob {
  position: fixed;
  /* background: #fef3f6; */
  background: #fff;
  z-index: 999;
  top: 0px;
  height: 100vh;
  width: 250px;
  left: -150%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.5s;
  padding: 13px 20px;
}
  .active-sidebar .nav-header .collapse-mob {
    left: 0%;
  }
  .sidebar-toggle.collapse-mob{
    left: 0%;
  }



  .mob-hide {
    display: none;
  }

  .cross-b {
    position: absolute;
    right: 15px;
    top: 15px;
  }
  .second-nav li a {
    display: block;
    flex-direction: column;
    text-align: left;
    padding-bottom: 10px;
}
.second-nav li a svg{
  margin-right: 11px;
}

.second-nav li a:hover{
  color: #000;
}

}

@media (max-width: 767px) {
  .login p {
    font-size: 14px;
    padding: 12px 18px;
}
  header.headser-mian ul.navbar-nav {
    margin: 50px auto;
  }

  .second-header.headser-mian {
    padding: 10px 0 10px;
  }

  .navbar-toggler:focus {
    border: 0px;
    outline: none;
    box-shadow: none;
  }

  .login a {
    font-size: 14px;
    padding: 14px 15px;
  }
  .drop-btn .btn-top {
    font-size: 14px;
  }
  
}
@media (max-width: 767px) {
  .nav-header .collapse-mob {
    width: 100%;
  }
  .dark-drop {
    margin-left: -145px;
  }
  
}