.bottom_social svg {
	font-size: 25px;
	padding: 10px;
	color: #9d9d9d;
}

.bottom_social ul {
	margin-top: 0 !important;
}

.bottom_social ul li::after {
	display: none;
}

.bottom_social ul a {
	font-family: 'Montserrat' !important;
	padding: 7px 20px !important;
}

.bottom_social li button {
	font-family: 'Montserrat' !important;
	font-weight: 400;
	font-size: 18px;
	line-height: 21px;
	padding: 0;
}

.bottom_social li select {
	border: none;
	padding: 0 32px;
	font-family: 'Montserrat' !important;
	font-weight: 400;
	font-size: 18px;
	line-height: 21px;
}

.bottom_social li {
	padding: 7px 11px !important;
}

.bottom_social li:first-child select {
	padding-left: 0;
}

.bottom_social li:first-child {
	padding-left: 0 !important;
}

.bottom_social li button:focus {
	outline: none;
	box-shadow: unset;
}

.bottom_social li:first-child select option {
	color: #000;
}

@media (max-width:767px) {
	.bottom_social li select {
		font-size: 14px;
	}

}