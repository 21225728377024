.react-pagination-btm ul {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.react-pagination-btm ul li {
  margin: 0 5px;
  list-style: none;
  width: auto;
  padding: 0px;
}
.react-pagination-btm ul li a {
  /* background: #ddd;
  width: 35px;
  height: 35px; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-weight: 500;
  padding: 0 8px;
  color: #000;
  text-decoration: none;
}
.react-pagination-btm ul li.selected a {
  /* background: #030619; */
  color: #fff;
  /* background-color: #dbdbdb; */
  font-weight: 700;
}
.react-pagination-btm ul li.selected{
  border: none !important;
  background: #e50e47;
  /* border-radius: 50%; */
}
.react-pagination-btm ul li.selected:hover a{
  color: #fff;
}




