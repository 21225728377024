.user_profile {
  /* padding-top: 85px; */
  /* background: repeating-linear-gradient(#BFBFBF, #BFBFBF 15.2%, #fff 15.2%, #fff 100%);
background-image:url("../../assets/images/bg_banner.png");
background-position: center;
background-size: cover;
background-repeat: no-repeat;
height: 205px; */
}
.bg_banner {
  padding: 85px 0px;
  background: repeating-linear-gradient(
    #bfbfbf,
    #bfbfbf 15.2%,
    #fff 15.2%,
    #fff 100%
  );
  background-image: url("../../assets/images/bg_banner.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 245px;
}
.lost_flag .PhoneInputCountrySelect {
  display: none;
}
.lost_flag .PhoneInputCountryIcon {
  position: absolute;
  top: -24px;
}
.lost_flag .PhoneInputCountrySelectArrow {
  display: none;
}
.mt-130 {
  margin-top: -122px;
}
.care_text p {
  text-align: left !important;
}
.care_text h6 {
  margin-bottom: 5px;
}
.btn_c {
  height: 48px;
  width: 100px;
  padding: 7px 20px;
}

.err-msg {
  color: #c31845;
  font-size: 15px;
  font-weight: 500;
}
img.flag_pos {
  position: absolute;
  top: 10px;
  left: 0px;
  width: 20px;
  display: none;
}
.darkbody .user_profile {
  padding-top: 0px;
  background: #353434;
}
.darkbody .top-space {
  padding-top: 75px !important;
}

.date_b {
  margin: 12px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.date_b input {
  background-color: #fff;
  width: 200px;
}
.text_change {
  position: absolute;
  z-index: 9;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  background: #dedede;
  width: 100%;
  font-size: 16px;
  padding: 10px 0px;
  font-weight: 500;
}
.dark-body .text_change {
  background: #0000009c !important;
  color: #000 !important;
}
.bg_bordered {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 13px !important;
  padding-bottom: 12px;
}
.user_profile h4 {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  width: 65%;
  margin-bottom: 20px;
}
.verify_b {
  background: #e50e47;
  display: inline-block;
  color: #fff;
  border-radius: 4px;
  padding: 4px 12px;
}
.darkbody .user_profile h4 {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
  width: 65%;
  margin-bottom: 20px;
}

.user_imgpro {
  width: 100%;
  border: 2px solid #fff;
}

.side_useprofile {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  align-items: center;
  padding-bottom: 16px;
}

.side_useprofile span img {
  margin-right: 5px;
}

.side_useprofile span {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000;
}

.darkbody .side_useprofile span {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
}

.side_useprofile span:nth-child(2) {
  font-weight: 500;
}

.side_verified {
  border-top: 0.3px solid #9f9f9f;
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.side_verified span a {
  color: #999999;
  font-weight: 500;
  font-size: 14px;
  text-decoration: underline;
}

.right_profilename {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  align-items: flex-start;
}

.right_profilename h3 {
  font-weight: 600;
  font-size: 24px;
}

.name_proifle {
  display: flex;
  justify-content: space-between;
  margin-top: 56px;
  border-bottom: 0.3px solid #b5b5b5;
  padding: 15px 18px;
  align-items: center;
}

.right_profilename p,
.name_proifle p,
.me_about p {
  background: #e50e47;
  border-radius: 4px;
  padding: 5px 15px;
  font-weight: 500;
  font-size: 14px;
}

.right_profilename span a {
  background: #e9961f;
  border-radius: 4px;
  margin: 8px 0 14px;
  display: inline-block;
  padding: 3px 10px;
  font-size: 14px;
  text-decoration: none;
}

.right_profilename span h5 {
  font-weight: 400;
  font-size: 18px;
}

.right_profilename span h5 img {
  margin-right: 10px;
  top: -2px;
  position: relative;
}

.upload_profile {
  /* display: flex; */
  align-items: center;
  gap: 15px;
  flex-direction: column;
  margin-top: 100px;
  /* text-align: center; */
}
.side_verified span {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 5px;
  word-break: break-all;
  font-size: 14px;
}
.btn:hover {
  background-color: #e50e47;
  color: #fff;
  opacity: 0.9;
}

.add-photos {
  margin: auto;
  text-align: center;
}

.upload_profile .right-side-size img {
  border-radius: 8px;
  height: 150px;
  width: 100%;
  object-fit: cover;
}

.upload_profile span img {
  height: unset;
  margin: 0 auto 10px;
  display: inherit;
}

.upload_profile span h6 {
  font-weight: 400;
  font-size: 10px;
}

.upload_profile span {
  background: rgba(0, 0, 0, 0.03);
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding: 43px 20px;
  margin-top: 20px;
}
/* .darkbody .upload_profile span {
  color: white;
  
} */

.nameproifle {
  background: #f2f2f2;
  border-radius: 12px;
}

.darkbody .nameproifle {
  background: #1b1b1b;
  color: #fff;
  border-radius: 12px;
}

.name_proifle span {
  font-weight: 500;
  font-size: 18px;
}

.list_nameprofile {
  margin: 25px 0;
  padding: 0 18px;
  display: inline-block;
  width: 100%;
}

.list_nameprofile table {
  width: 100%;
  line-height: 50px;
}

.list_nameprofile tr {
  border-bottom: 0.3px solid #b5b5b5;
}

.list_nameprofile tr td {
  font-size: 14px;
  font-weight: 400;
}

.list_nameprofile tr td:nth-child(1) {
  font-weight: 500;
}

.me_about {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 25px 0;
}

.me_about span {
  font-weight: 500;
  font-size: 18px;
  color: #000000;
}

.darkbody .me_about span {
  font-weight: 500;
  font-size: 18px;
  color: #fff;
}

.Looking_for {
  padding-left: 0;
  list-style: none;
  display: inline-block;
  margin: 10px 0 30px;
}

.Looking_for li span {
  background: #f2f2f2;
  padding: 8px 15px;
  color: #000;
  font-size: 14px;
  border-radius: 20px;
  font-weight: 500;
  display: block;
}
.Looking_for {
  gap: 7px;
  display: flex;
  flex-wrap: wrap;
}
.Looking_for li {
  display: inline-block;
  transition: transform 0.2s;
}
.img-profile-sec {
  height: 360px;
  width: 100%;
}
.img-profile-sec img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.Looking_for li:hover {
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.Looking_for li a {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 24px;
  padding: 10px;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.3);
  text-decoration: none;
}

.darkbody .Looking_for li a {
  background: #1b1b1b;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 24px;
  padding: 10px;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.25px;
  color: rgba(255, 255, 255, 0.3);
}
.darkbody .side_verified {
  color: #fff;
}

.darkbody div,
.darkbody span {
  color: #fff;
}
.darkbody .upload_profile span {
  border: 0.5px solid rgb(255 255 255 / 30%);
}
.darkbody .interesttabs .tab > label {
  opacity: 0.8;
}
.darkbody .btn-c {
  background: rgb(255 255 255 / 8%);
}
.height-textarea .form-control {
  height: auto !important;
}

.side_verified .btn-verify {
  border-radius: 4px;
  font-size: 14px;
  padding: 3px 19px;
  margin-top: 10px;
  height: 36px;
  border: 0;
}

@media (max-width: 767px) {
  .card-contact {
    padding: 0px;
  }
  .darkbody .top-space {
    padding-top: 54px !important;
  }
  .darkbody .right_profilename h3 {
    color: #fff !important;
  }

  .upload_profile .right-side-size {
    width: calc(34% - 14px);
  }
  .user_imgpro {
    width: unset;
    margin: 0 auto;
    display: inherit;
  }

  .Looking_for li a {
    display: inline-block;
    margin: 10px 0;
    font-size: 13px;
  }

  .right_profilename h3 {
    font-size: 18px;
    color: #000 !important;
  }
}

@media (max-width: 767px) {
  .upload_profile .right-side-size {
    width: calc(50% - 8px);
  }
  .upload_profile {
    margin-top: 20px;
  }
  .text_change {
    font-size: 14px;
    padding: 5px 0px;
  }
}
