body {
    margin: 0 auto;
    padding: 0;
    background: #fff;
    font-family: 'Montserrat', sans-serif;
    overflow-x: hidden;
    scroll-behavior: smooth;
}
.darkbody{
    background: #1b1b1b;
}
.darkbody h1, .darkbody h2, .darkbody h3, .darkbody h4, .darkbody h5, .darkbody h6, .darkbody p {
    color: #fff;
    
}


h1, h2, h3, h4, h5, h6, p {
    padding: 0px;
    margin: 0px;
    color: #000;

}

.cursor-pointer {
    cursor: pointer
}

@font-face {
    font-family: 'Glitten';
    src: url('./assets/fonts/Glitten-Regular.eot');
    src: url('./assets/fonts/Glitten-Regular.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Glitten-Regular.woff2') format('woff2'),
        url('./assets/fonts/Glitten-Regular.woff') format('woff'),
        url('./assets/fonts/Glitten-Regular.ttf') format('truetype'),
        url('./assets/fonts/Glitten-Regular.svg#Glitten-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rufina';
    src: url('./assets/fonts/Rufina-Regular.woff2') format('woff2'),
        url('./assets/fonts/Rufina-Regular.woff') format('woff');
}


@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-Regular.ttf') format('ttf');
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-Bold.ttf') format('truetype');
    ;
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-ExtraBoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-Black.woff2') format('woff2'),
        url('./assets/fonts/Montserrat-Black.woff') format('woff'),
        url('./assets/fonts/Montserrat-Black.svg#Montserrat-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-Bold.woff2') format('woff2'),
        url('./assets/fonts/Montserrat-Bold.woff') format('woff'),
        url('./assets/fonts/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-ExtraBold.woff2') format('woff2'),
        url('./assets/fonts/Montserrat-ExtraBold.woff') format('woff'),
        url('./assets/fonts/Montserrat-ExtraBold.svg#Montserrat-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-SemiBold.woff2') format('woff2'),
        url('./assets/fonts/Montserrat-SemiBold.woff') format('woff'),
        url('./assets/fonts/Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-Regular.woff2') format('woff2'),
        url('./assets/fonts/Montserrat-Regular.woff') format('woff'),
        url('./assets/fonts/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-Medium.woff2') format('woff2'),
        url('./assets/fonts/Montserrat-Medium.woff') format('woff'),
        url('./assets/fonts/Montserrat-Medium.svg#Montserrat-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-ExtraLight.woff2') format('woff2'),
        url('./assets/fonts/Montserrat-ExtraLight.woff') format('woff'),
        url('./assets/fonts/Montserrat-ExtraLight.svg#Montserrat-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-Thin.woff2') format('woff2'),
        url('./assets/fonts/Montserrat-Thin.woff') format('woff'),
        url('./assets/fonts/Montserrat-Thin.svg#Montserrat-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}