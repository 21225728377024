.subscriptionHolderInner{
    width: 80%;
    margin: auto;
    .interesttabs{
        .selected{
            background: #f2f2f2;
            border: 2px solid #000000 !important;
        }
        .subsViewDetailBtn{
            background-color: #d61548;
            color: white;
            float: right;
            margin-top: -30px;
        }
       
    }
}
.titleSpan {
    padding: 13px 20px;
    background: #f5c4d1;
    font-weight: 600;
    display: block;
    margin-bottom: 50px;
}
.subscriptionHolderInner{
    .subsViewDetailBtn{
        background-color: #d61548;
        color: white;
        float: right;
    }
}
.btn-pay{
    background-color: #d61548;
    color: white;
    float: right;
    margin-top: 30px;
}

.selectedTab {
    background-color: #d61548!important;
    padding: 13px 20px;
    color: #fff;
    font-weight: 600;
}
.nav-sub-data {
    justify-content: center;
    gap: 50px;
    display: flex;
    align-items: center;
}
.outer_subscribe {
    padding: 50px 40px;
    border: 1px solid rgba(0,0,0,.2);
    margin-bottom: 20px;
    border-radius: 8px;
    transform: scaleY(1);
    transition: all .5s;
    text-align: center;
}
.subsViewDetailBtn{
    background-color: #d61548;
color: #fff;
height: 48px;
width: 100%;
display: flex;
align-items: center;
justify-content: center;
border: 0;
margin-top: 30px;
font-size: 14px;
font-weight: 500;
}
.outer_subscribe.selected{
    transform: scaleY(1.1);
}
.subsViewDetailBtn:hover{
    background-color: #d61548;
    color: #fff;
opacity: .9;}

.pHolder-main{
    font-size: 15px;
    min-height: 270px;
    padding-top: 20px;
}

.price-place {
    font-size: 20px;
    font-weight: 500;
    padding-top: 11px;
}
.price-place span{
    font-size: 30px;
    font-weight: 600;
}
.time-holder {
    background-color: #d6154840;
    font-size: 16px;
    font-weight: 600;
    color: #000;
    padding: 9px 27px;
    display: inline-block;
    border-radius: 3px;
}
.plans_out {
    text-align: center;
    padding: 20px 38px;
}
.plans_out ul{
    padding-top: 20px;
    text-align: left;
    padding-left: 111px;
}
.plans_out ul li{
    padding-bottom: 6px;
    font-size: 14px;
    position: relative;
}
.plans_out ul li::after {
    content: '';
    position: absolute;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: #d61548;
    left: -18px;
    top: 6px;
}

@media (max-width:767px) {
.outer_subscribe {
    padding: 20px 30px;}

    .pHolder-main {
        padding-top: 20px;
    }
    .plans_out ul {
        padding-left: 43px;
    }
    .outer_subscribe.selected {
        transform: scaleY(1);
    }
}
