.Edit_your_info {
  width: 580px;
  margin: 0 auto;
}
.darkbody .Edit_your_info {
  width: 580px;
  margin: 0 auto;
  color: #fff;
}

/* .Edit_your_info .close {
    position: absolute;
    right: 13px;
    top: 10px;
} */
.Edit_your_info .close {
    position: absolute;
    right: 5px;
    top: 5px;
  }
.Edit_your_info h1 {
  font-weight: 500;
  font-size: 20px;
}

.Edit_your_info .modal-header {
  display: flex;
  justify-content: space-around;
  border-bottom: 0;
}

.Edit_your_info input {
  height: 48px !important;
  font-weight: 500;
  font-size: 14px !important;
  color: #000;
}
.darkbody .Edit_your_info input {
  height: 48px !important;
  font-weight: 500;
  font-size: 14px !important;
  background-color: #1b1b1b !important;
  border: #868686 0.5px solid;
  color: #fff;
}
.darkbody .sidebar.sidebar-inner {
  background-color: rgb(36 35 35) !important;
}
.Edit_your_info input::placeholder {
  color: #000 !important;
}
.darkbody .Edit_your_info input::placeholder {
  color: #fff !important;
}
.darkbody .flex-column li:hover .btn {
  background-color: #333 !important;
}
.darkbody .modal-content {
  background: #3b3b3b;
  color: #fff;
}
.darkbody .outer_subscribe {
  background: #363636;
}
.darkbody .titleSpan {
  background: #414141;
}
.darkbody .time-holder {
  background-color: #d7d7d740;
}
.darkbody .modal-content {
  background: #2f2f2f;
}
.darkbody .overflow_top .accordion-item{
  background-color: transparent !important;
}
.darkbody .accordion-button.collapsed{
  background-color: transparent !important;
}
.darkbody .accordian-page .accordion-button.collapsed{
  background-color: #1b1b1b !important;
}

.darkbody .accordion-button:not(.collapsed)::after {
  background-color: #dedede;
  filter: invert(1);
}
.darkbody button.accordion-button.collapsed::after {
  background-color: #dedede;
  filter: invert(1);
}




.darkbody .overflow_top .accordion-button {
  color: #fff;
}



.darkbody .flex-column li .btn.active {
  background-color: #333 !important;
  color: #000 !important;
}
.Edit_your_info .valida_use {
  position: relative;
}

.Choose_Username button {
  margin-top: 31px;
  background: #e50e47;
  border: 1px solid rgba(13, 13, 13, 0.1);
  border-radius: 12px;
  padding: 13px 36px;
  width: 100%;
}

.Choose_Username .valid-feedback {
  font-weight: 500;
}

.Choose_Username h6,
.photo_preview h6 {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #a3a3a3;
}

.cancel_save {
  display: flex;
  gap: 20px;
  width: 80%;
  margin: 15px auto;
}

.cancel_save button {
  font-weight: 500;
  font-size: 14px;
  padding: 11px 20px;
  margin-top: 0;
  border-radius: 6px;
  border: none;
}

.cancel_save button:nth-child(1) {
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: transparent;
}
.darkbody .cancel_save button:nth-child(1) {
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
}

.cancel_save button:nth-child(2) {
  background: #e50e47;
  color: #ffffff;
}

.btn-close {
  color: #fff !important;
}

/* .edi_info_user button {
    background: #F2F2F2;
    border: 1px solid rgba(13, 13, 13, 0.1) !important;
    border-radius: 12px;
    font-weight: 500;
    font-size: 16px;
    color: #000 !important;
} */

.edi_info_user .accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
  transform: rotate(0deg) !important;
}

.looking_check label {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 0;
}

.looking_check {
  display: flex;
  gap: 10px;
}

.user_checkprofile {
  display: block;
  position: relative;
  padding-left: 23px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.user_checkprofile input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0 !important;
  width: 0;
}

.user_checkprofile .checkmark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: transparent;
  border: 0.8px solid rgba(0, 0, 0, 0.3);
  border-radius: 3px;
}

.user_checkprofile input:checked ~ .checkmark {
  background: #0067fb;
  border: 0.8px solid rgba(0, 0, 0, 0.3);
  border-radius: 3px;
}
.darkbody .user_checkprofile input:checked ~ .checkmark {
  background: #fff;
  border: 0.8px solid rgba(0, 0, 0, 0.3);
  border-radius: 3px;
}

.edi_info_user h3 {
  font-weight: 500;
  font-size: 16px;
  color: #aaaaaa;
}

.edi_info_user h3 span {
  color: #e50e47;
}

.age {
  font-weight: 400;
  font-size: 16px;
}

.info-accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color: #f2f2f2;
  border: 1px solid rgba(13, 13, 13, 0.1) !important;
  border-radius: 12px;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
}

.darkbody .info-accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #fff;
  text-align: left;
  background-color: #1b1b1b;
  border: 1px solid rgba(13, 13, 13, 0.1) !important;
  border-radius: 12px;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
}

.accordion-body {
  padding: 0;
}
.darkbody .btn-dark {
  color: #fff;
  background: #1b1b1b;
  border: none;
}
.darkbody .btn-darko {
  color: #a3a3a3;
  background: #1b1b1b;
  border: none;
}
.btn-dark {
  color: #000;
  background: none;
  border: none;
}
.btn-darko {
  color: #000;
  background: none;
  border: none;
}
.btn-dark:hover {
  color: #000;
  background: none;
  border: none;
}
.btn-darko:hover {
  color: #000;
  background: none;
  border: none;
}

.location-details {
  width: 100%;
  background-color: #f2f2f2 !important;
  padding: 10px 15px;
  border-radius: 11px;
  border: none;
}

.loca_icon {
  position: absolute;
  right: 12px;
  bottom: 10px;
}

.step_form label {
  color: #000;
}

.rotate_photo button,
.step_form button {
  background: #e50e47;
  border-radius: 8px;
  border: none;
  font-size: 12px;
  font-weight: 500;
  padding: 8px 18px;
}

.step_form ul li {
  list-style: none;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.3);
  text-align: center;
  z-index: 9;
}

.step_form ul li.active {
  background: #e50e47;
  color: #e50e47;
  border: none;
  position: relative;
}

.step_form ul li i {
  display: none;
}

.step_form ul li.active i {
  display: block;
  position: absolute;
  font-size: 9px;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
}

.step_form ul:after {
  position: absolute;
  content: "";
  border: 2px dotted rgba(0, 0, 0, 0.3);
  top: 50%;
  left: 50%;
  width: 339px;
  transform: translate(-50%, -50%);
}

.step_form ul {
  padding-left: 0;
  margin: 0 60px 24px;
}

.step_form h4 {
  font-size: 20px;
}

.step_form .location {
  width: 352px;
  height: 48px;
  background: #f2f2f2;
  border: 1px solid rgba(13, 13, 13, 0.1);
  border-radius: 12px;
}

.step_form .location img {
  position: absolute;
  left: -30px;
  transform: translate(-50%, -50%);
  top: 50%;
}

.step_form .location input::placeholder {
  color: #0d0d0d !important;
}

.step_form .location img:last-child {
  left: 93%;
}

.step_form textarea {
  height: 82px !important;
  resize: none;
  font-weight: 400;
  font-size: 12px;
  color: #00000061;
  padding-top: 0;
}

.five_true {
  padding: 0 80px;
  display: flex;
  /* justify-content: space-evenly; */
  gap: 8px;
  flex-wrap: wrap;
}
.modal_t .modal-title {font-size: 18px;}
.five_true button {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  font-size: 14px;
  font-weight: 400;
  margin: 0px 0px;
  display: flex;
  flex-wrap: wrap;
  padding: 8px 18px;
}

.Looking_userFor .five_true {
  padding: 20px 0px 0;
}

.Looking_userFor h3 {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  color: #aaaaaa;
}

.left-rotare-btn.show-btns.btn-public {
  display: flex;
  justify-content: center;
  margin: 18px 0;
  background: #e50e47;
  color: #fff;
  margin-top: 15px;
  border: none;
  border-radius: 10px;
  padding: 10px 30px;
}
.terms_sec h4 {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 26px;
}
.terms_sec{
  margin-top: 30px;
}
.terms_sec h5 {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 8px;
  line-height: 28px;
}
.terms_sec h3 {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  line-height: 24px;
  text-align: center;
}
.terms_sec p {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
  line-height: 20px;
  text-align: center;
}
.terms_sec input{
  height: 15px !important;
  width: 15px;
}


.terms_sec .btn-public {
  display: flex;
  justify-content: center;
  background: #e50e47;
  color: #fff;
  margin-top: 15px;
  border: none;
  border-radius: 10px;
  padding: 10px 30px;
}

.terms_sec label{
  padding-left: 5px;
    font-weight: 500;
    font-size: 14px;
}











@media (max-width: 540px) {
.Edit_your_info {
  width: 100%;
  margin: 0 auto;
}
}

@media (max-width: 540px) {
  .sign-u {
    padding-left: 5px;
    font-size: 14px;
}
  .Choose_Username button {
    margin-top: 15px;
  }
}
.selected-interest {
  background-color: #e50e47 !important;
  color: white;
}
.rc-slider-handle {
  border: solid 2px #e50e47 !important;
}
.rc-slider-track, .rc-slider-tracks {
  background-color: #e50e47;
}
.rc-slider{
  margin-bottom: 12px;
}