.contact-sec{
    background-image: url("../../assets/images/contact-img.jpg");
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    padding: 60px;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    background-attachment: fixed;}

.nue-d p{
  margin-bottom: 0px;  
  color: #fff;
}

.nue-d p a{
    margin-bottom: 0px;  
    color: #cda780;
    text-decoration: none;
  }

  .bg-blur-b h1 {
    line-height: 1em;
    margin-bottom: 30px;
    color: #cda780;
    font-size: 38px;
    font-weight: 600;
}
.nue-d{
    margin-bottom: 90px;
}
.nue-dd h3 {
    line-height: 25px;
    margin-bottom: 20px;
    color: #cda780;
    font-size: 20px;
    font-weight: 600;
}

    .nue-dd {
        width: 100%;
        margin-bottom: 20px;
    }

.nue-c{
    margin-top: 40px;
    color: #000;
    text-align: center;
    font-size: 17px;
    font-weight: 500;
}

.press-p p{
    color: #cda780;
}




@media (max-width: 767px) {
.contact-sec {
    padding: 124px 20px 40px 20px;
    height: auto;
}
.nue-d {
    margin-bottom: 24px;
}
.bg-blur-b h1 {
    margin-bottom: 22px;
    font-size: 22px;
}
}





