.add-photo {
  padding: 18px 0 0;
}

.add-photo p {
  text-align: center;
  margin: 30px 0 20px;
  color: #a3a3a3;
  font-size: 16px;
}

.add-photo p a {
  color: #e50e47;
}

.cursor-pointer {
  cursor: pointer;
}

.add-photo .col-md-4:last-child .add-media {
  border: 0;
}

.add-photo h4 {
  text-align: center;
  font-size: 20px;
  margin-bottom: 24px;
}
.add-photo .row {
  text-align: center;
  display: flex;
  justify-content: center;
}

.darkbody .forget_dont {
  background: #1b1b1b;
  padding: 20px 0;
}

.forget_dont {
  background: #f0f0f0;
  padding: 20px 0;
}

.forget_dont span {
  color: #e50e47;
  font-weight: 500;
}
.email_forget {
  padding: 0 70px;
}

.email_forget .spam_folder {
  display: flex;
  gap: 60px;
}

.email_forget .spam_folder {
  display: flex;
  gap: 60px;
  margin: 30px 0;
  align-items: center;
}

.email_forget .spam_folder img {
  width: 30px;
}

.email_forget .spam_folder h6 {
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
}

.email_forget .spam_folder span {
  color: #e50e47;
}
.rotate_photo button,
.step_form button {
  background: #e50e47;
  border-radius: 8px;
  border: none;
  font-size: 12px;
  font-weight: 500;
  padding: 8px 18px;
}

.add-media {
  border-right: 1px solid #ddd;
  height: 100%;
  text-align: center;
}

.add-media span {
  font-size: 16px;
  color: #000;
  font-weight: 500;
}

.darkbody .add-media span {
  font-size: 16px;
  color: #fff;
  font-weight: 500;
}

.add-media img {
  margin: 0 auto 11px;
  display: table;
}

.add-photo .col-md-4:last-child .add-media {
  border: 0;
}

/* .image-upload-show {
    text-align: center;
} */

.image-upload p {
  text-align: center;
  color: #a3a3a3;
  padding: 0 30px;
}

.cont-photo {
  width: 100%;
  text-align: center;
  display: table;
  color: #e50e47 !important;
}

.rotate {
  max-width: 375px;
  margin: 10px auto 0;
}

.rotate h3 {
  font-size: 12px;
}

.left-rotare {
  display: flex;
  align-items: center;
  justify-content:center;
  margin: 12px 0 30px;
  gap: 15px;
}

.left-rotare-in span {
  border: 1px solid #0000003d;
  color: #0000003d;
  padding: 7px 12px;
  border-radius: 5px;
  font-size: 13px;
  margin-right: 3px;
}

.left-rotare-btn button {
  background: #e50e47;
  color: #fff;
  border: 0;
  font-weight: 500;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 13px;
  margin-left: 100%;
  margin-top: 10px;
  justify-content: center;
  margin: 20px 0px;
}
.steap-form-list ul{
  justify-content: center;
}
.left-rotare-btn.show-btns {
  display: flex;
  justify-content: center;
  margin: 18px 0;
}

.left-rotare-btn.show-btns button {
  margin: 0 8px;
}

.profile-photo {
  width: 300px;
  height: 200px;
}
.profile-photo img{
  border-radius: 0px !important;
}
.steap-form-list ul {
  display: flex;
  padding: 0;
  margin: 0;
  max-width: 80%;
  margin: 0 auto;
}
#aboutMeInput {
  width: 100%;
  height: 100px !important;
}

.steap-form-list ul li {
  list-style: none;
  width: 16.6%;
  position: relative;
  z-index: 1;
}

.darkbody .steap-form-list ul li.active span {
  background: #e50e47;
  color: #fff;
  border-color: #e50e47;
}

.steap-form-list ul li.active span {
  background: #e50e47;
  color: #fff;
  border-color: #e50e47;
}

.steap-form-list ul li span {
  font-size: 14px;
  border: 1px solid #bbb;
  color: #bbb;
  width: 26px;
  height: 26px;
  display: flex;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  background: #fff;
}

.darkbody .steap-form-list ul li span {
  font-size: 14px;
  border: 1px solid #bbb;
  color: #bbb;
  width: 26px;
  height: 26px;
  display: flex;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  background: #303030;
}

.steap-form-list ul li:first-child::before {
  opacity: 0;
}

.steap-form-list ul li::before {
  width: 100%;
  height: 2px;
  border: 1px dashed #bbb;
  content: "";
  position: absolute;
  top: 11px;
  z-index: -1;
  left: -60%;
}

.steap-form-list ul li.active::before {
  border-color: #e50e47;
  border-style: double;
}

.preview-image {
  width: 100%;
  height: 280px;
}

.looks-great {
  text-align: center;
  font-size: 19px;
  margin: 22px 0;
}

.location-jio .form-group img {
  margin-right: 15px;
}

.location-jio .form-group {
  display: flex;
  align-items: center;
  max-width: 80%;
  margin: 24px auto 0;
}

.location-jio label {
  text-align: center;
  width: 100%;
  margin: 40px 0 0;
}

.location-error {
  padding: 0px 105px;
  color: red;
  font-size: 15px;
}

.accordian-page .accordion-button {
  background: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
  border-top: 1px solid #ddd !important;
  border-bottom: 1px solid #ddd !important;
  border-radius: 0 !important;
  font-weight: 600;
}
.accordian-page .accordion-button{
  color: #000;
}
.darkbody .accordian-page .accordion-button {
  background: #1b1b1b !important;
  border: 0 !important;
  box-shadow: none !important;
  border-top: 1px solid #ddd !important;
  border-bottom: 1px solid #ddd !important;
  border-radius: 0 !important;
  font-weight: 600;
  color: #fff !important;
}
.accordion-button:focus{box-shadow: none;}
.accordian-page .accordion-button span {
  margin: 0 auto;
  font-weight: 400;
}

.accordion-body ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  background: #fff;
}
.darkbody .accordion-body ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  background: #1b1b1b;
  color: #fff;
}
.accordion-body ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.accordion-body ul li.active {
  background: #e50d46;
  border-color: #e50e47;
  color: #fff;
}
.button active {
  background: #e50d46;
  border-color: #e50e47;
  color: #fff;
}
.love-list ul li.active {
  background: #e50d46;
  border-color: #e50e47;
  color: #fff;
}

.accordion-body ul li {
  list-style: none;
  border: 1px solid #919191;
  font-size: 13px;
  padding: 11px 5px;
  min-width: 132px;
  margin-right: 9px;
  margin-bottom: 12px;
  text-align: center;
  border-radius: 4px;
  margin-top: 6px;
  cursor: pointer;
}

.darkbody .mci {
  background-color: #fff;
  padding: 3px;
}

.darkbody .llogo {
  background-color: #fff;
  padding: 1px;
  border-radius: 5px;
}

.darkbody .dobInput {
  color-scheme: dark;
}

.accordion-item {
  border: 0;
}

.edi_info_user .accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.wi3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
  transform: rotate(0deg) !important;
  
}


button.accordion-button.collapsed::after {
  transform: rotate(271deg) !important;
  --bs-accordion-btn-icon-width: 1rem !important;
}

.accordion-button:not(.collapsed){
  background-color: transparent;
  box-shadow: none;
}
button.accordion-button::after {
  color: #e50d46 !important;
}

button.accordion-button {
  /* background: #f2f2f2 !important;
  border: 1px solid rgba(13, 13, 13, 0.1) !important; */
  border-radius: 0px;
  font-weight: 500;
  font-size: 14px;
  padding: 0px;
}

.love-list ul {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 30px 0;
  padding: 0;
}

.love-list ul li {
  border: 1px solid #9b9b9b;
  list-style: none;
  color: #9b9b9b;
  padding: 5px 12px;
  border-radius: 60px;
  margin: 5px 3px;
  font-size: 14px;
}

.lv-from textarea {
  border-radius: 12px;
  border: 1px solid rgba(13, 13, 13, 0.1);
  background: #f2f2f2;
  width: 100%;
  height: 92px;
  padding: 7px 11px;
  font-size: 13px;
  color: #000;
}

.darkbody .lv-from textarea {
  border-radius: 12px;
  border: 1px solid #919191;
  background: #1b1b1b;
  width: 100%;
  height: 92px;
  padding: 7px 11px;
  font-size: 13px;
  color: white;
}

.lv-from label {
  width: 100%;
  margin-bottom: 5px;
}

.search-location {
  z-index: 100;
}

.pac-container {
  z-index: 10000 !important;
}

.otp-inputs input{
  width: 42px !important;
  height: 35px  !important;
  color: #e63531 !important;
  text-align: center;
  margin: 5px 5px;
  border: none;
  font-weight: 600;
  border-bottom: 1px solid #e63531;
  font-size: 18px !important;
}
.otp-inputs {
    text-align: center;
}
.ott-input div {
  justify-content: center;
  gap: 18px;
  margin-bottom: 20px;
}
.resend-otps{
  color: #000 !important; 
  text-align: center;
  margin: 10px 0px;
}

.btn-verify {
  background: #E50E47;
  color: #fff;
  border-radius: 9px;
  text-decoration: none;
  font-size: 16px;
  padding: 9px 42px;
  margin-top: 10px;
  height: 48px;
  border: 0;
  font-weight: 500;
}
.resend-otps button {
  background-color: transparent;
  border: 0px;
  color: #E50E47;
  font-weight: 500;
  margin-top: 14px;
}
.add-media {
  background-color: #f2f2f2;
  border: 1px dashed #E50E47;
  padding: 5px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}
.accordian-page{
  width: 100%;
  text-align: center;
}
.accordian-page .accordion-body{
  padding: 10px 10px;
}


/* @media (max-width:991px) {
	.steap-form-list ul li::before {
		width: 100%;
		height: 2px;
		border: 1px dashed #bbb;
		content: "";
		position: absolute;
		top: 11px;
		z-index: -1;
		left: -58%;
	}

}

@media (max-width:500px) {
	.steap-form-list ul li::before {
		width: 100%;
		height: 2px;
		border: 1px dashed #bbb;
		content: "";
		position: absolute;
		top: 11px;
		z-index: -1;
		left: -52%;
	}

}
@media (max-width:445px) {
	.steap-form-list ul li::before {
		width: 100%;
		height: 2px;
		border: 1px dashed #bbb;
		content: "";
		position: absolute;
		top: 11px;
		z-index: -1;
		left: -46%;
	}

}

@media (max-width:410px) {
	.steap-form-list ul li::before {
		width: 100%;
		height: 2px;
		border: 1px dashed #bbb;
		content: "";
		position: absolute;
		top: 11px;
		z-index: -1;
		left: -42%;
	}

} */
.gallery-heading{
  text-align: left;
  font-weight: 600;
  margin: 10px 0px;
  font-size: 16px;
}
.product-info{
  text-align: center;
  padding: 10px 0px;
}
.product-info .time-holder{
  padding: 15px 20px;
  margin-bottom: 10px;
}
.ElementsApp input{
  border-bottom: 1px solid #ccc !important;
}
.CardField-child {
  border-bottom: 1px solid #ccc !important;
}
.upload_acc .accordion-button {
  padding: 12px 0px;
    margin-bottom: 10px;
}
.left-rotare-btn{
  text-align: center;
}

.input_ic {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}
.input_icc {
  position: absolute;
  left: 10px;
}
.input_ic input {
  border: 1px solid #e9295bfa;
  border-width: 1px;
  padding-left: 48px;
}
.accordian-page .accordion-button{
  padding: 14px 0px;
  border-top: 0px !important;
}

.collapse-edit .accordion-collapse.collapse {
  border-bottom: 1px solid #ddd !important;
    background: #fff;
    border: 1px solid #ccc;
    padding: 0px;
    border-radius: 6px 7px 8px 8px;
    position: absolute;
    width: 100%;
    z-index: 99;
    box-shadow: 0px 4px 6px rgba(0,0,0,.1);
}
.collapse-edit .accordion-body{
  padding: 7px 10px;
  border-bottom: 1px solid rgba(0,0,0,.1);
 
}
.collapse-edit .accordion-body label{
  cursor: pointer;
}

.head_1{
  font-size: 26px;
  color: #000;
  font-weight: 600;
  margin :30px 0px 20px 0px;
  text-align: center;
}
.card-contact {
  padding: 24px;
  box-shadow: 0px 3px 11px rgba(0,0,0,.1);
  margin-bottom: 15px;
  border-radius: 6px;
}
































